import styled from "styled-components";

export const CardBox = styled.div`
    width: 100%;
    min-height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 9;
    transition: all 0.5s ease-in-out;
    position: relative;
    background: #fff;
    border-radius: 70% 100% 30% 70%;
    padding: 60px 50px 60px 40px;
    margin-bottom: 30px;
    @media screen and (max-width: 1420px){
        min-height: 408px;
    }
    @media screen and (max-width: 576px){
        margin-bottom: 30px;
        padding: 40px 30px 40px;
    }
    @media screen and (max-width: 400px){
        padding: 35px 30px 25px;
        min-height: 330px;
    }
    
    &:after {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        background: url(/assets/images/home/pattren1.png) no-repeat 0 0;
        background-size: cover;
        transition: all 0.5s ease-in-out 0s;
    }
    &:hover{
        background: radial-gradient(50% 50% at 50% 50%, #42D6FD 0%, #04CAFF 100%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        background: linear-gradient(
        180deg
        , rgba(0,201,255,1) 0%, rgba(101,220,252,1) 80%);
        /* border-radius: 189px 198px 39px 138px; */
        color: #fff;
    }
    /* &hover::before {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    &:hover:after {
        opacity: 0.2;
    } */
    img{
        height: 80px;
        @media screen and (max-width: 576px){
            height: 65px;
        }
    }

    h4{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 27px;
        margin: 30px 0px 20px;
        @media screen and (max-width: 576px){
            font-size: 1.4rem;
            margin: 20px 0px 12px;
        }
        &:hover{
            color: #fff;
        }
    }

    p{
        font-size: 0.938rem;
        line-height: 23px;
        &:hover{
            color: #fff;
        }
    }

`;