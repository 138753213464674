import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";

import styled from "styled-components";

const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;


export default function MobileDevelopment(){
    return(
        <>
          <Navbar1 />

          <ServicesBanner deskbanner="assets/images/services/mobile-dev.jpg" mobbanner="assets/images/services/mobile-apps-dev.jpg" />

          <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="Mobile Develoment" />

                    <h2 className="svtitle">Expert ReactNative, Flutter Team</h2>  
                    
                    <p>We are Thailand's leading mobile app development company, building robust apps for brands that require mobile-first solutions.</p>
                  
                
                </div>
            </div>  

            <NumberSpeak />

            <SuccessStories>
               <div className="container">
                   <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                   </div>
                    
                    <div className="row mt-4">
                       
                        <div className="col-lg-12 text-center">
                             <div className="stories-content">
                                <p>We are Thailand's leading mobile app development company, building robust apps for brands that require mobile-first solutions.</p>
                           
                            </div>   
                        </div>
                    </div>
               </div>
           </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}