import { useState } from "react";
import { NewsletterContainer, NewsletterCard } from "./Newsletter-style";
import { toast } from "react-toastify";
import { conf } from "../../config";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const submitHandler = () => {
    fetch(`${conf.server}/api/subscribe`, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((res) => {
        setEmail((pv) => "");

        toast.success("Thank you for subscribing");
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <NewsletterContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10px col-md-10">
              <NewsletterCard>
                <div className="row align-items-center">
                  <div className="col-xl-5 col-lg-5 col-md-5">
                    <h4>Subscribe Our Newsletter</h4>
                    <p>Subscribe to our newsletter & stay Updated!</p>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                    <div className="subscribe-card">
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      <button
                        type="submit"
                        className="subbtn"
                        onClick={submitHandler}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </NewsletterCard>
            </div>
          </div>
        </div>
      </NewsletterContainer>
    </>
  );
}
