import { CardBox } from "./Cards-style"

export default function Cards(props){
    return(
        <>
           <CardBox className="Services-box style-4">
               <img src={props.icon} alt="Service image" />
               <h4>{props.title}</h4>
               <p>{props.description}</p>
          </CardBox>
        </>
    )
}