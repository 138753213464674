import styled from "styled-components";

export const OurTeamContainer = styled.section`
   width: 100%;
   .mainteamrow{
       width: 100%;
       position: relative;
   }
   
   .teamcontent{
        width: calc(100% - 0px);
        padding-left: 100px;
        @media screen and (max-width: 576px) {
            padding-left: 0px;
        }
   }
`;

export const TeamMember = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
`;

export const TeamRightCard = styled.div`
  width: 100%;
    .react-multi-carousel-list{
       position: static;
       ul{
           li{
              padding: 15px;
           }
       }
       .react-multiple-carousel__arrow--left{
            left: 8%;
            bottom: 5%;
            @media screen and (max-width: 860px){
                left: 36%;
                bottom: -9%;
            }
            @media screen and (max-width: 576px){
                left: 21%;
                bottom: -7%;
            }
            @media screen and (max-width: 380px){
                left: 19%;
            }
            &:before{
                content: "\f177";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                @media screen and (max-width: 576px) {
                    font-size: 24px;
                }
            }
       }
       .react-multiple-carousel__arrow--right{
            left: 18%;
            bottom: 5%;
            @media screen and (min-width: 1680px) and (max-width: 1920px){
                left: 17% !important;
            }
            @media screen and (min-width: 1321px) and (max-width: 1680px){
                left: 18.4% !important;
            }
            @media screen and (max-width: 13020px){
                left: 20%;
            }
            @media screen and (max-width: 860px){
                left: 55%;
                bottom: -9%;
            }
            @media screen and (max-width: 576px){
                left: 53%;
                bottom: -7%;
            }
            &:before{
                content: "\f178";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                @media screen and (max-width: 576px) {
                    font-size: 24px;
                }
            }
       }

       .react-multiple-carousel__arrow{
         width: 130px;
         height: 50px;
         background-color: #ffffff;
         color: #000;
         box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
         transition: all 0.2s ease-in-out;
         z-index: 5;
         @media screen and (max-width: 576px) {
            width: 100px;
            height: 38px;
           
         }
         &:hover{
            background: linear-gradient(90deg, rgba(0,201,255,1) 0%, rgba(101,220,252,1) 80%);
            color: #ffffff;
         }
         &::before{
             color: #000;
         }
       }
   }
`;

