
import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";
import HeadingH2 from "../../HeadingComponent/HeadingH2";


export default function ArEducation() {
    return (
        <>

            <Navbar1 />
            <ServicesBanner deskbanner="assets/images/services/AR-edu-web.jpg" mobbanner="assets/images/services/Ar-edu-mob.jpg" />
            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="AR Education" />
                    <h2 className="svtitle">Augmented Reality in Education</h2>
                    <p>Augmented Reality tools and plugins offer a remarkable solution for educational institutions. Experiential learning is a potent form of education and allows students to witness the context surrounding the topics being taught.</p>
                    <p>AR presents teachers with the chance to aid students in comprehending abstract ideas. By utilizing the interaction and experimentation provided by AR technology, teachers can elevate the classroom experience, impart new skills, motivate students, and foster their curiosity in exploring new academic pursuits.</p>
                </div>
            </div>

            <NumberSpeak />

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}