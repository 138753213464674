import Navbar1 from "../../Header/Navbar/Navbar1";
import { TeamContainer, HeadTitle, ConsultContainer, MessageCEO } from "./OurTeam-style";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TeamExpertCards from "../about/ExpertTeamCards";
import { FaUsers } from "react-icons/fa";
import { BsFillChatRightTextFill } from "react-icons/bs";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import HeadingOne from "../Headings/HeadingOne";
import Banner from "../BannerComponent/Banner";

export default function OurTeam() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <Navbar1 />

      <Banner title="Our Team" subtitle="VARA EdTech is looking for a dependable, committed Team Member to join our developing organization." homeurl="/" activelink="Our Team" />


      <div className="container">
        <HeadTitle>
          <div className="title-effect title-effect-2">
            <div className="ellipse text-center"></div> <span><FaUsers /></span>
          </div>
          <h5>CREATIVE TEAM</h5>
          <HeadingOne title="Meet Our Expert Team Member Will Ready For Your Service" />
          <p>VARA EdTech is seeking a reliable and dedicated team member to join our growing organization. The team member's responsibilities include managing all tasks assigned by the team leader or manager, working collaboratively with other team members, adhering to company policies, and acting as a positive brand ambassador at all times.</p>
        </HeadTitle>

        <div className="row">

          <MessageCEO>
            <div className="row">
              <div className="alineanim">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <img src="assets/images/team/sunjay.jpg" alt="" />
                </div>

              </div>

            </div>
            <h1>Message from The CEO</h1>
            <h2>As a customer-focused company, our aim is to generate sustainable profit growth through the creation of new value and the delivery of innovative solutions.
            </h2>
            <h2>At VARA EdTech, we value and celebrate every accomplishment, big or small, to maintain a positive and motivated work environment.</h2>

          </MessageCEO>

        </div>
        <div className="row justify-content-center">
          <div className="row">
            <div className="col-lg-12">
              <TeamContainer>
                <Carousel className="" responsive={responsive} autoPlay infinite={true} autoPlaySpeed={5000} arrows={false} swipeable={true}>
                  <div><TeamExpertCards image="assets/images/team/sunjay.jpg" title="Sunjay Kumar" designation="CEO & Founder" /></div>
                  <div><TeamExpertCards image="assets/images/team/atul.jpg" title="Atul Goel" designation="Technical Lead" /></div>
                  <div><TeamExpertCards image="assets/images/team/prakash.jpg" title="Prakash Das" designation="Project Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/vivek.jpg" title="Vivek" designation="Marketing Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/baris.jpg" designation="Full Stack" title="Barış Alagöz" /></div>
                  <div><TeamExpertCards image="assets/images/team/bayram.jpg" title="BayRam" designation="Full Stack" /></div>
                  <div><TeamExpertCards image="assets/images/team/peter.jpg" title="Peter" designation="Full Stack" /></div>
                  <div><TeamExpertCards image="assets/images/team/shoriful.jpg" title="Shoriful" designation="Front End" /></div>
                  <div><TeamExpertCards image="assets/images/team/ee.jpg" title="Emmenuel Emmanuel Eneyoh" designation="Backend Dev" /></div>
                  <div><TeamExpertCards image="assets/images/team/zain.jpg" title="Zain" designation="Android Developer" /></div>
                  {/* <div><TeamExpertCards image="assets/images/team/emel.jpg" title="Emmeneul Eoreoluwa" designation="Full Stack" /></div> */}
                  <div><TeamExpertCards image="assets/images/team/pradeep.jpg" title="Pradeep" designation="Full Stack" /></div>
                  <div><TeamExpertCards image="assets/images/team/ammar.jpg" title="Ammar" designation="Full Stack" /></div>
                  <div><TeamExpertCards image="assets/images/team/farahan.jpg" title="Farahan" designation="Front End" /></div>
                  <div><TeamExpertCards image="assets/images/team/chibwe.jpg" title="Chibwe" designation="Front End" /></div>
                  <div><TeamExpertCards image="assets/images/team/chebs-black.jpg" title="Chebs Black" designation="MERN Stack" /></div>

                </Carousel>
              </TeamContainer>
            </div>
          </div>
        </div>
      </div>

      <ConsultContainer>
        <div className="container">
          <div className="alineanim">
            <img src="assets/images/team/lineam.png" alt="" />
          </div>
          <h2>Consult with us today to take your business to the next level of success!</h2>

          <a href="https://wa.me/+66948877955" className="letstalk" target="_blank" rel="noopener noreferrer"><BsFillChatRightTextFill /> Let’s talk</a>

          <div className="alineanim1">
            <img src="assets/images/team/lineam.png" alt="" />
          </div>
        </div>
      </ConsultContainer>

      <Newsletter />
      <Footer ishome={false} />

    </>
  )
}