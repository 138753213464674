import { TeamCardBox, SocialIcon } from "./ExpertTeamCards-style";
import { FaExternalLinkAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function TeamExpertCards(props) {
    return (
        <>
            <TeamCardBox className="team-member about-team">
                <div className="team-images">
                    <img className="img-fluid rounded box-shadow" src={props.image} alt="" />
                    <NavLink to="/our-team" className="team-link"><span><FaExternalLinkAlt /></span></NavLink>
                </div>
                <SocialIcon className="social-icons social-colored circle team-social-icon"> 
                    <ul>
                        <li className="social-facebook">
                            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                        </li>
                        <li className="social-twitter">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                        </li>
                        <li className="social-gplus">
                            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>

                        </li>
                        <li className="social-linkedin">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        </li>
                    </ul>
                </SocialIcon>
                <div className="team-description"> <span>{props.designation}</span>
                    <h5>{props.title}</h5>
                </div>
            </TeamCardBox>
        </>
    )
}