import styled from "styled-components";


const H3 = styled.h3`
    font-size: 1.6rem;
    color: #1c1d3e;
    font-weight: 500;
    font-family: 'Ubuntu';
    line-height: 42px;
    margin-bottom: 20px;
    text-transform: capitalize;
@media screen and (max-width: 500px) {
        font-size: 1.3rem;
        line-height: 34px;
 }
`;

export default function HeadingH3({ title }){
    
    return(
        <>
            <H3>{title}</H3>
        </>
    )
}