import styled from "styled-components";

export const CardBox = styled.div`
    width: 100%;

    img{
        height: 70px;
    }

    h4{
        font-size: 18px;
        font-weight: 600;
        margin: 18px 0px 15px;
    }
`;

