import styled from "styled-components";

export const NewsletterContainer = styled.div`
   width: 100%;
   margin-top: 30px;
   margin-bottom: -20px;
   visibility: hidden;
   @media screen and (max-width: 576px){
      margin-top: 40px;
   }
`; 

export const NewsletterCard = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 30px;
    @media screen and (max-width: 576px){
        padding: 20px 20px;
    }

    h4{
        font-family: "Nunito",sans-serif;
        text-transform: capitalize;
        font-style: normal;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 30px;
        color: #101729;
        margin-bottom: 8px;
        @media screen and (max-width: 576px){
            font-size: 1.2rem;
        }
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        color: #101729;
        @media screen and (max-width: 576px){
            font-size: 0.8rem;
        }
    }
    .subscribe-card{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        padding: 5px;
        align-items: center;
        column-gap: 10px;
        input{
            width: 80%;
            height: 40px;
            padding-left: 15px;
            color: #6B6B6B;
            border: none;
            font-size: 14px;
        }
        button{
            background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: #FEF9F8;
            font-size: 1rem;
            font-weight: 400;
            padding: 10px 30px;
            border: none;
            @media screen and (max-width: 576px){
                padding: 10px 15px;
                font-size: 0.9rem;
            }
        }
    }
`; 