import { MainContaper, SubTitle, ContentCard, Button } from "./About-style"
import HeadingH1 from "../../HeadingComponent/HeadingH1"
import { FaInfo } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function About() {
    return (
        <>
            <MainContaper>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ContentCard className="about-content">
                                <div className="title-effect title-effect-2">
                                    <div className="ellipse"></div> <span><FaInfo /></span>
                                </div>

                                <SubTitle>About Us</SubTitle>
                                <HeadingH1 title="VARA EdTech bridges the gap between you and an interactive and innovative world"></HeadingH1>
                                <p>Our mission is to establish ourselves as one of Thailand's leading and trustworthy IT organizations by continuously enhancing our services and providing exceptional customer support. We approach each potential client's project with the same level of professionalism and enthusiasm as we do our own.</p>

                                <Button>
                                    <NavLink to="/about-us">More About Us</NavLink>
                                </Button>
                            </ContentCard>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="aboutImg text-ccenter">
                                <img src="/assets/images/home/home-about.png" alt="about" />
                            </div>
                        </div>
                    </div>

                </div>
            </MainContaper>
        </>

    )
}