export default function ThreeDVirtual(){
    return(
        <>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-12 mt-3 virtualframe">
                        <iframe src="https://ddc-make.com/3d-virtual-tour/q_condo/index.html" width="100%" title="3D Virtual"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}