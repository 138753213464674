import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";

export default function VrEducation(){
    return(
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/vr-education.png" mobbanner="assets/images/services/vr-edu-mobile.jpg" />

            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="VR Education" />

                    <h2 className="svtitle">Virtual Teacher</h2>  
                    
                    <p>Communicate effectively! 3D characters can be placed in a physical or digital space and viewed through the VARA EdTech AR mobile app, similar to popular space opera films.

</p>
                    
                    <p>These characters can be utilized for announcements, interactive training sessions, product demonstrations, in-store brand ambassador demonstrations, augmented packaging, and enhanced marketing materials.</p>     
                    
                    <h2 className="svtitle">WebAR 3Dimensional products</h2>

                    <p>Elevate your eCommerce website with Web AR, which allows access to your models directly from the web without the need for an app!</p>
               
                    <p>Studies have shown that the use of Web AR models increases clicks, conversions, and sales while reducing returns on eCommerce pages.</p>
                
                </div>
            </div>

            <NumberSpeak />

            <Newsletter />

            <Footer ishome={false} />
        </>
    )
}