import { TeamCardBox, SocialIcon } from "./TeamCards-style"
import { FaExternalLinkAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn  } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function TeamCards(props){
    return(
        <>
            <TeamCardBox className="team-member">
                <div className="team-images">
                    <img className="img-fluid rounded box-shadow" src={props.image} alt="" />
                    <NavLink to="/our-team" className="team-link"><span><FaExternalLinkAlt /></span></NavLink>
                </div>
               
                <div className="team-description hdec"> 
                    <span>{ props.designation }</span>
                    <h5>{ props.title }</h5>

                    <SocialIcon className="social-icons social-colored circle team-social-icon">
                        <ul>
                            <li className="social-facebook">
                                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                            </li>
                            <li className="social-twitter">
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                            </li>
                            <li className="social-gplus">
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{fontSize: "17px"}}><FaInstagram /></a>
                            </li>
                            <li className="social-linkedin">
                                <a href="https://www.linkedin.com/company/vara-edtech/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                            </li>
                        </ul>
                    </SocialIcon>
              </div>
            </TeamCardBox>
        </>
    )
}