import HeadingH2 from "../../HeadingComponent/HeadingH2";
import { FaInfo } from "react-icons/fa";
import { MainContainer, ContentCard, MisionLisit, List, ImageCard, Button } from "./OurVisionMision-style";
import { NavLink } from "react-router-dom";

export default function OurVisionMision() {
    return (
        <>
            <MainContainer>
                <div className="container">
                    <div className="row align-items-center mvision">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ImageCard className="image-column vm-desktop-view">
                                <div className="blink-img">
                                    <img className="img-fluid blinkblink" src="/assets/images/home/pattren1.png" alt="" />
                                </div>
                                <img src="/assets/images/home/vm.png" alt="Vision" />
                            </ImageCard>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ContentCard>
                                <div className="title-effect title-effect-2">
                                    <div className="ellipse"></div> <span><FaInfo /></span>
                                </div>

                                <HeadingH2 title="OUR VISION & MISSION" />

                                <div className="vm-mobile-view">
                                    <ImageCard className="image-column">
                                        <div className="blink-img">
                                            <img className="img-fluid blinkblink" src="/assets/images/home/pattren1.png" alt="" />
                                        </div>
                                        <img src="/assets/images/home/vm.png" alt="Mission" />
                                    </ImageCard>
                                </div>
                                <p>Our mission and vision is to become one of Thailand's premier and dependable IT organizations by continuously improving our services and delivering exceptional customer support, treating every potential client project with the same level of professionalism and enthusiasm as our own.
                                </p>
                                <p>VARA EdTech is driven to promote the widespread adoption of advanced education technology through offering training and upskilling programs for entry-level and intermediate professionals seeking proficiency in VR and AR software and tools, and to enter a rapidly growing job market.</p>

                                <MisionLisit>
                                    <List>VR/AR Apps</List>
                                    <List>Educational Trainings</List>
                                    <List>Healthcare Apps</List>
                                    <List>Virtual Real Estate</List>
                                </MisionLisit>

                                <Button>
                                    <NavLink to="/about-us">More About Us</NavLink>
                                </Button>

                            </ContentCard>
                        </div>

                    </div>
                </div>
            </MainContainer>
        </>
    )
}