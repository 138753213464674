import { BannerCards } from  "./Banner-style"
import { NavLink } from "react-router-dom";

export default function Banner({ title, subtitle, activelink, homeurl }){
    return(
        <>
            <BannerCards>
                <div className="container">
                    <div className="row justify-content-sm-between align-items-center">
                        <div className="col-xl-9 col-lg-9 col-md-9">
                            <h3>{ title }</h3>
                             <p>{ subtitle }</p>  
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3">
                            <nav className="page-breadcrumb">
                                <ul className="breadcrumb justify-content-sm-end">
                                    <li className="breadcrumb-item">
                                        <NavLink to={homeurl}>Home</NavLink>
                                    </li>                             
                                    <li className="breadcrumb-item">{ activelink }</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    
                </div>
                <div className="page-title-pattern">
                    <img className="img-fluid" src="/assets/images/bg/pattern-bg.png" alt="" />
                </div>
            </BannerCards>

        </>
    )
}