import styled from "styled-components"

const CardsBoxes = styled.div`
    width: 100%;
    min-height: 360px;
    padding: 20px 30px 30px 45px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 189px 198px 39px 138px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    transition: all 0.4s ease-in-out;
    &:hover{
        background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 189px 198px 39px 138px;
        color: #fff;
    }
    &:hover h4{
        color: #fff;
    }
    &:hover p{
        color: #fff;
    }
    img{
        width: 75px;
    }
                        
    h4{
        color: #101729;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    p{
        color: #101729;
        font-weight: 400;
        font-size: 0.875;
        line-height: 24px;
    }
`;


export default function TrainingCards(props){
    return(
        <>
           <CardsBoxes>
               <img src={ props.icon } alt="Training images" />  
               <h4>{ props.title }</h4>
               <p>{ props.text }</p>
           </CardsBoxes>
        </>
    )
}