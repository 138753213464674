import styled from "styled-components";

const H1 = styled.h1`
    font-size: 2.1rem;
    color: #1c1d3e;
    font-weight: 500;
    font-family: 'Ubuntu';
    line-height: 42px;
    margin-bottom: 20px;
    text-transform: capitalize;
    @media screen and (max-width: 500px) {
        font-size: 1.5rem;
        line-height: 34px;
    }
`;

export default function HeadingH1({ title }){
    
    return(
        <>
            <H1>{title}</H1>
        </>
    )
}