import { MainPageContainer, Counter } from "./HappyClients-style";
import CountUp from 'react-countup';


export default function HappyClients(){
    
    return(
        <>
        <MainPageContainer>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <Counter className="counter">
                            <img src="/assets/images/home/happy-client-icon.png" alt="Happy Client" />                 
                            <h3><CountUp start={0} end={11}></CountUp></h3>
                            <h4>Happy Clients</h4>
                        </Counter>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <Counter className="counter">
                            <img src="/assets/images/home/project-icon.png" alt="Happy Client" />
                            <h3><CountUp start={0} end={11}></CountUp></h3>
                            <h4>Finished Projects</h4>
                        </Counter>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <Counter className="counter">
                            <img src="/assets/images/home/skilled-icon.png" alt="Happy Client" />
                            <h3><CountUp start={0} end={20}></CountUp></h3>
                            <h4>Skilled Experts</h4>
                        </Counter>
                    </div>
                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <Counter className="counter">
                            <img src="/assets/images/home/visitor-icon.png" alt="Happy Client" />
                            <h3><CountUp start={0} end={10}></CountUp></h3>
                            <h4>Visitor</h4>
                        </Counter>
                    </div> */}
                </div>
            </div>
        </MainPageContainer>  
        </>
    )
}   