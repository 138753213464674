import TeamExpertCards from "./ExpertTeamCards";
import { OurTeamContainer } from "./OurExpertTeam-style";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HeadingThree from "../Headings/HeadingThree";


export default function OurExpertTeam(){
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <>
           <OurTeamContainer>
                <div className="container">
                     <h5>CREATIVE TEAM</h5>
                     <HeadingThree title="Meet Our Expert Team Member Will Ready For Your Service" />
                    <div className="row justify-content-end">
                        <div className="col-xl-11 col-lg-11 col-md-12">
                        <Carousel className="" responsive={responsive} autoPlay infinite={true} autoPlaySpeed={5000} arrows={false}  swipeable={true}>
                            <div><TeamExpertCards image="assets/images/team/sunjay.jpg" designation="Founder and CEO" title="Sunjay Kumar" /> </div>
                            <div><TeamExpertCards image="assets/images/team/baris.jpg" designation="Full Stack" title="Barış Alagöz" /></div>
                            <div><TeamExpertCards image="assets/images/team/atul.jpg" designation="Technical Lead" title="Atul Goel" /></div>
                            <div><TeamExpertCards image="assets/images/team/prakash.jpg" designation="Project Manager" title="Prakash Das" /></div>
                        </Carousel>  
                        </div>
                    </div>                    
                   
                </div>
            </OurTeamContainer> 
        </>
    )
}