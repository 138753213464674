import React,{useEffect} from "react";
import { BannerContainer, ButtonCard } from "./Banner-style";
import { BsWhatsapp } from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from "react-router-dom";

export default function Banner(){

    useEffect(() => {
        AOS.init({
          duration : 1000
        });
      }, []);

    return(
        <>
          <BannerContainer>
               <div className="container-fluid">
                   <div className="row py-5 align-items-end">
                       <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="banner-img">
                               <img src="/assets/images/home/vara-hero.png" alt="Hero" />
                           </div>
                       </div>
                       <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="banner-content">
                               <h3 data-aos="fade-up">VARA EdTech<br/>
                               <span>Connects</span><br/>
                               you with Interactive<br/>
                               world and innovation</h3>

                               <ButtonCard className="connects-btn d-flex mpt-5" data-aos="fade-up" data-aos-duration='2000'>
                                   <button type="button" className="btn contactBtn">
                                        <NavLink to="/contact-us" className="nav-link">Contact Us Now</NavLink>
                                   </button>
                                   <button type="button" className="btn consultBtn">
                                        <a href="https://wa.me/+66948877955" target="_blank" rel="noopener noreferrer" className="nav-link"><BsWhatsapp /> Talk to a consultant</a>
                                   </button>
                               </ButtonCard>

                               <div className="circle-img">
                                  
                               </div>
                              
                           </div>
                       </div>
                   </div>
               </div>
          </BannerContainer>
        </>
    )
}