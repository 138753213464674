import styled from "styled-components";


export const MainContainer = styled.header`
   width: 100%;
   position: absolute;
   top: 0;
   z-index: 1000;
   .navbar{
        width: calc(100% - 20px);
        position: relative;
        background-color: #ffffff !important;
        margin-left: 10px;
        box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
        padding: 0px;
        @media screen and (max-width: 576px){
            background-color: #fff !important;
        }
        /* &:before {
            content: "";
            background: #f9f7fc;
            width: 96%;
            height: 30px;
            position: absolute;
            left: 2%;
            bottom: -10px;
            z-index: -1;
            box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
        } */
        /* &:after {
            content: "";
            background: #fcfbfd;
            width: 92%;
            height: 30px;
            position: absolute;
            left: 4%;
            bottom: -20px;
            z-index: -9;
            box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
        } */
        .dardk-logo{
            display: block;
        }
        .white-logo{
            display: none;
        } 
        &.fixed{
            width: 100%;
            padding: 0px;
            margin-left: 0px;
            background-color: #fff !important;
            position: fixed;
            top: 0px;
            left: 0px;
            transition: all 0.s ease-in-out;
            animation-name: top;
            animation-duration: 1s;
            box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 18%);
            &:before{
                width: 0px;
                height: 0px;
                box-shadow: none;
            }
            &:after{
                width: 0px;
                height: 0px;
                box-shadow: none;
            }
            .dardk-logo{
                display: block;
            }
           .white-logo{
               display: none;
           } 
        }
       
        .navbar-brand{
            img{
                width: 120px;
            }
        }
        .navbar-nav{
            gap: 25px;
            @media screen and (max-width: 576px){
                gap: 0px;
                padding-bottom: 12px;
            }
           .nav-item{
               position: relative;

               .nav-link{
                    display: block;
                    color: #030351;
                    padding: 12px 4px;
                    position: relative;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.18;
                    text-decoration: none;
                    transition: all 0.4s ease-in-out;
                    font-family: 'Nunito',sans-serif;
                    &.isactive{
                        color: #2575fc !important; 
                    }
                    &.conactbtn{
                        background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%);
                        border-radius: 4px;
                        color: #fff !important;
                        padding: 10px 15px !important;
                        @media screen and (max-width: 576px){
                            width: auto;
                            margin-left: 0px;
                            background: #fff !important;
                            color: #292929 !important;
                            padding: 8px 10px !important;
                        }
                        &:hover{
                            margin-top: -3px;
                        }
                        &:hover::before{
                            color: transparent !important;
                            text-shadow: none !important;
                        }
                    }
                    @media screen and (max-width: 576px){
                        padding: 8px 10px;
                        font-size: 14px;
                    }
                    &:active{
                        color: #2575fc !important;
                    }
                    &:hover{
                        color: #2575fc;
                    }
                    &:before{
                        position: absolute;
                        bottom: -5px;
                        left: 50%;
                        color: transparent;
                        content: '.';
                        font-size: 2em;
                        transition: text-shadow 0.3s, color 0.3s;
                        pointer-events: none;
                        animation: opacitychange 1s ease-in-out infinite;
                        line-height: 1;
                        @media screen and (max-width: 576px){
                            left: 10%;
                        }
                    }
                    &:hover::before{
                        color: #2575fc;
                        text-shadow: 10px 0 #1c1d3e, -10px 0 #1c1d3e;
                    }
                    &:active::before{
                        color: #2575fc !important;
                        text-shadow: 10px 0 #1c1d3e, -10px 0 #1c1d3e !important;
                    }
                }

            .dropdown-menu{
                padding-top: 0px;
                padding-bottom: 0px;
                box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
                transition: all 0.4s ease-in-out;
                transition-delay: .2s;
                transition-duration: .4s;
                opacity: 0;
                min-width: 240px;
                z-index: 5;
                border-radius: 4px;
                border: none;
                display: flex;
                visibility: hidden;
                z-index: 1;
                transform: translateY(40px);
                flex-direction: column;
                @media screen and (max-width: 576px){
                    opacity: inherit !important;
                    display: none !important;
                    position: inherit !important;
                }
                .dropdown-item{
                    padding: 10px 20px;                
                    border-bottom: 1px solid #eee;
                    transition: all 0.4s ease-in-out;
                    display: block;
                    font-size: 0.938rem;
                    color: #030351;
                    font-family: 'Nunito',sans-serif;
                    font-weight: 500;
                    &:hover{
                        color: #2575fc;
                        background-color: #fff;
                        padding-left: 20px;
                    }
                }
            }

                &:hover .dropdown-menu{
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(15px);
                    display: block;
                    z-index: 100;
                    @media screen and (max-width: 576px) { 
                        opacity: inherit !important;
                        display: block !important;
                        transform: translateY(8px) !important;
                    }
                }
                
            }
        }


        .dropdown-menu > li.sub-dropdown{
           position: relative;
            .sub-dropdown-menu{
                min-width: 200px;
                position: absolute;
                top: 0px;
                left: 230px;
                opacity: 0;
                /* display: none; */
                visibility: hidden;
                margin: 0px;
                padding: 0px;
                z-index: 1;
                flex-direction: column;
                background-color: #fff;
                list-style: none;
                transform: translateY(30px);
                transition: all 0.4s ease-in-out;
                box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
                @media screen and (max-width: 576px){
                    min-width: 100%;
                    top: 44px;
                    left: 0px;
                    background-color: #fff;
                    z-index: 1;
                }
            }
          &:hover .sub-dropdown-menu{
                opacity: 1;
                transform: translateY(0px);
                display: block;
                visibility: visible;
                @media screen and (max-width: 576px){
                    /* transform: translateY(44px); */
                    background-color: #fff;
                    
                }
            }

            .sub-dropdown-menu{
                .dropdown-item{
                    padding-left: 20px;
                    @media screen and (max-width: 576px){
                        padding-left: 30px;
                    }
                    :hover{
                        padding-left: 25px;
                    }
                }
            }
       } 

       .sub-dropdown{
            .desktop-view{
                display: block !important;
                @media screen and (max-width: 576px){
                    display: none !important;
                }
            }
            .mobile-view{
                display: none !important;
                @media screen and (max-width: 576px){
                    display: block !important;
                }
            }
        }    

   }



   @keyframes top
        {
        0% {
            top: -50px;
        }
       
        }



`;
  