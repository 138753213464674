import { MainContainer } from "./ClientLogo-style"

export default function ClientLogo(){
    return(
        <>
           {/* <MainContainer>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                            <div className="logo-cards">
                                <img src="assets/images/about/client-logo.png" alt="Client Logo" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                            <div className="logo-cards">
                                <img src="assets/images/about/client-logo.png" alt="Client Logo" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                            <div className="logo-cards">
                                <img src="assets/images/about/client-logo.png" alt="Client Logo" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                            <div className="logo-cards">
                                <img src="assets/images/about/client-logo.png" alt="Client Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </MainContainer>  */}
        </>
    )
}