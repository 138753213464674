import styled from "styled-components";

export const MainCardsBoxes = styled.div`
  width: 100%;
  margin-bottom: 30px;
    .port-img{
        width: 100%;
    }

    .portfolio-content{
        padding: 20px 22px 15px;
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-top: -40px;
        box-shadow: 0 0 10px rgb(0 0 0 / 15%);
        z-index: 5;
        position: relative;
        background-color: #fff;
        border-radius: 10px;
        @media screen and (max-width: 576px){
            width: calc(100% - 30px);
            margin-left: 15px;
            padding: 25px 18px 15px;
        }
        h5{
            color: #007bff;
            font-size: 14px;
            margin-bottom: 6px;
            font-weight: 400;
        }
        h4{
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 12px;
            font-size: 1.1rem;
            color: #101729;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color: #363636;
            font-family: 'Open Sans';
            margin-bottom: 7px;
        }
        .view-link{
            color: #008FFF;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            text-decoration: none;
        }
    }
`;