import { NumberContainer, NumberContent, Counter, NumberSpeakCard } from "./NumberSpeak-style";
import { AiOutlineFieldBinary } from "react-icons/ai";
import HeadingTwo from "../Headings/HeadingTwo";
import CountUp from 'react-countup';

export default function NumberSpeak(){
    return(
        <>
          <NumberContainer>
              <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <NumberContent>
                                    <div className="title-effect title-effect-2">
                                        <div className="ellipse"></div> <span><AiOutlineFieldBinary /></span>
                                    </div>

                                    <HeadingTwo title="Number Speak Louder Then Word" />
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                            </NumberContent>
                        </div> 

                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <NumberSpeakCard>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <Counter className="counter">
                                            <img src="/assets/images/home/happy-client-icon.png" alt="Happy Clients" />
                                            <h3><CountUp start={0} end={11}></CountUp></h3>
                                            <h4>Happy Clients</h4>
                                            
                                        </Counter>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6">    
                                        <Counter className="counter">
                                            <img src="/assets/images/home/project-icon.png" alt="Finished Projects" />
                                            <h3><CountUp start={0} end={11}></CountUp></h3>
                                            <h4>Finished Projects</h4>
                                        </Counter>
                                    </div>
                                </div>
                            </NumberSpeakCard>
                        </div>

                    </div>
              </div>
          </NumberContainer>


           

        </>
    )
}