import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import Newsletter from "../../NewsletterComponent/Newletter";
import Banner from "../BannerComponent/Banner";
import TrainingCards from "./TrainingCards";

export default function Training(){
    return(
        <>
           <Navbar1 />

           <Banner title="Taining" subtitle="You need a creative software development company, like VARA EdTech, to really get serious about the immersive digital realm." homeurl="/" activelink="Taining" />
          
           <div className="container">
                <div className="text-center mb-5">
                    {/* <HeadingOne title="Taining" /> */}
                </div>   

               <div className="row">
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                        icon="assets/images/training/web-design-icon.png" 
                        title="Web Designing" 
                        text="Web design is an important aspect in creating a user-friendly and visually appealing website. Our web design course offers hands-on training in the latest design trends and technologies, giving you the skills you need to create stunning and effective websites that attract and retain users. Whether you are a beginner or a seasoned pro, our course covers everything you need to know to become a successful web designer." />
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                       icon="assets/images/training/web-dev-icon.png" 
                       title="Web Development" 
                       text="Unlock the potential of the web with our comprehensive web development course. Gain the skills and knowledge to build dynamic, interactive, and user-friendly websites. Our experienced instructors guide you through the latest technologies and techniques, equipping you with the skills to bring your web development vision to life. Start your journey to become a web development expert today." />
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                       icon="assets/images/training/ccna.png" 
                       title="CCNA" 
                       text="CCNA (Cisco Certified Network Associate) is a popular entry-level certification for network technicians and engineers. It verifies your ability to install, configure, and troubleshoot network systems, and provides a foundation for future network-related studies. Obtaining a CCNA certification can enhance your marketability and career opportunities in the field of networking." />
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                       icon="assets/images/training/VR-icon.png" 
                       title="AR / VR" 
                       text="AR/VR is the future of technology and is revolutionizing industries such as education, healthcare, real estate, and entertainment. With AR/VR, people can experience virtual environments and interact with digital objects in a more immersive way. By taking our AR/VR course, you will gain expertise in creating engaging AR/VR apps and solutions that will have a positive impact in multiple industries. Join our AR/VR course today!" />
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                       icon="assets/images/training/app-dev-icon.png" 
                       title="Mobile App Development" 
                       text="Advance your career with our comprehensive Mobile Development course. Learn the latest technologies and gain hands-on experience building mobile apps for iOS and Android platforms. With expert instructors and a focus on practical applications, you'll be equipped to enter the growing mobile development field with confidence." />
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4">
                       <TrainingCards 
                       icon="assets/images/training/seo-icon.png" 
                       title="SEO" 
                       text="Attract attention with SEO services! Boost your online presence and drive more traffic to your website with our proven SEO strategies. Our experts stay up-to-date with the latest search engine algorithms and techniques to ensure your website ranks higher on search engine results pages and attracts more qualified leads. Invest in your online success with our comprehensive SEO services!" />
                   </div>
               </div>
           </div>


           <Newsletter />

           <Footer ishome={false} /> 
        
        </>
    )
}