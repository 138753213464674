import styled from "styled-components";

export const OurTeamContainer = styled.section`
  width: 100%;
  h5{
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 15px;
      color: #1c1d3e;
      @media screen and (max-width: 576px){
          text-align: center;
      }
  }
  h3{
      max-width: 760px;
      font-family: "Nunito",sans-serif;
      text-transform: capitalize;
      color: #1c1d3e;
      @media screen and (max-width: 576px){
          text-align: center;
      }
  }
  .react-multi-carousel-list{
      padding: 50px 0px;
      .react-multi-carousel-item{
          padding: 12px;
      }  
  }
`;