import styled from "styled-components";

const H1 = styled.h1`
    font-size: 2rem;
    color: #1c1d3e;
    font-weight: 600;
    font-family: "Nunito",sans-serif;
    line-height: 36px;
    margin-bottom: 20px;
    text-transform: capitalize;
    text-align: justify;
    @media screen and (max-width: 500px) {
        font-size: 1.3rem;
        line-height: 34px;
    }
`;

export default function HeadingOne({ title }){
    
    return(
        <>
            <H1>{title}</H1>
        </>
    )
}