import Navbar1 from "../../Header/Navbar/Navbar1";
import Banner from "../BannerComponent/Banner";
import styled from "styled-components";
import HeadingOne from "../Headings/HeadingOne";
import ITService from "./ITServices";
import OurExpertTeam from "./OurExpertTeam";
import ClientLogo from "./ClientLogo/ClientLogo";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import ClientsFeedback from "./ClientsFeedback";

const AboutCard = styled.section`
   width: 100%;
   .ab-content-card{
       img{
           width: 150px;
       }
       h1{
         font-family: 'Nunito', sans-serif;
       }
       p {
        text-align: justify;
       }
   }
`;


export default function AboutUs() {
    return (
        <>
            <Navbar1 />

            <Banner title="About Us" subtitle="We’ve building Modern and high Software" activelink="About Us" homeurl="/" />

            <AboutCard>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img">
                                <img src="assets/images/home/home-about.png" alt="About" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="ab-content-card">
                                {/* <img src="/assets/images/varaedtech-logo-dark.png" alt="Logo" /> */}

                                <HeadingOne title="VARA EdTech Connects you with Interactive world and innovation" />
                                <p>You require the services of a creative software development firm such as VARAedTech to fully delve into the world of immersive digital technology.</p>
                                <p>VARA EdTech is particularly renowned for delivering exceptional augmented and virtual reality software and AI applications.</p>
                                <p>Are you aiming to create an app that enables your customers to virtually try on clothes from the comfort of their homes or preview furniture styles? Do you have a grasp of virtual or mixed reality software for building and executing your ideas? Do you seek the assistance of a talented team of coders and designers to develop your next machine learning app? </p>
                                <p>VARA EdTech has got you covered! We are a state-of-the-art creative production house based in Thailand with an unwavering commitment to quality, creativity, and performance. Our team comprises of highly skilled technicians who can provide you with robust virtual tools, AI applications, VR, AR outsourcing, and other relevant services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </AboutCard>

            <ITService />

            <OurExpertTeam />

            <ClientLogo />

            <ClientsFeedback />

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}