import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";
import styled from "styled-components";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;

export default function AugmentedReality() {
    return (
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/augmented-r.jpg" mobbanner="assets/images/services/augmented-ban.jpg" />
            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title="Augmented Reality" />
                    <h2 className="svtitle">Turning Dreams into Reality</h2>
                    <p>Augmented Reality (AR) is an augmented version of the real physical world, achieved using digital visuals, sounds, or other sensory stimuli and provided by technology. This is a growing trend, especially among companies interested in mobile computing and business applications.</p>
                    <p>With the increasing collection and analysis of data, one of the main goals of augmented reality is to highlight specific features of the physical world, improve our understanding of those features, and make them intelligent and accessible so that they can be applied to real-world applications. to extract valuable insights. Such big data can, among other things, help inform corporate decision-making and provide insight into consumer spending habits.</p>

                </div>
            </div>
            <NumberSpeak />

            <SuccessStories>
                <div className="container">
                    <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                    </div>

                    <div className="row mt-4">

                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>Augmented reality is a technology that digitally puts a 'realworld' experience with a 3D picture. In the real world, this gives the user the appearance that the virtual entity co-exists with them.</p>
                                <p>In gaming, AR is also used, giving players a more realistic experience and involving more senses. But in the shopping experience, it is helpful as well.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SuccessStories>

            <Newsletter />
            <Footer ishome={false} />


        </>
    )
}