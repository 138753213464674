import { ClientCardsBox } from "./ClientCards-style";


export default function ClientCards(props){
    return(
        <>
          <ClientCardsBox>
               <div className="clients-img">
                   <img src={ props.image } /> 
               </div>
               <div className="client-contents">
                    
                    <div className="testimonial-content">
                        <p><span>VARA EdTech</span> is one of the finest software house, we are highly satisfied by the quality of work and delivery of system by time.</p>    
                        
                        <div className="testimonial-caption">
                            <h5>{ props.name }</h5>
                            <label>IT Manager of Husverket</label>
                        </div>   
                    </div>
               </div>
          </ClientCardsBox>
        </>
    )
}