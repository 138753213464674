import {BrowserRouter, Routes, Route} from "react-router-dom";
import { useEffect } from "react";
import Home from "./components/Home/Home";
import Healthcare from "./components/pages/Services/Healthcare";
import Services from "./components/pages/Services/Services";
import Ecommerce from "./components/pages/Services/Ecommerce";
import VrEducation from "./components/pages/Services/VrEducation";
import WebDevelopment from "./components/pages/Services/WebDevelopment";
import SoftwareDevelopment from "./components/pages/Services/SoftwareDevelopment";
import MobileDevelopment from "./components/pages/Services/MobileDevelopment";
import RealState from "./components/pages/Services/RealEstates";
import ArEducation from "./components/pages/Services/ArEducation";
import ArchitectureDesign from "./components/pages/Services/ArchitectureDesign";
import VisualizationMarketing from "./components/pages/Services/VisualizationMarketing";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import OurTeam from "./components/pages/OurTeamComponent/OurTeam";
import Portfolio from "./components/pages/OurPortfolioComponent/Portfolio";
import Training from "./components/pages/Training/Training";
import AugmentedReality from "./components/pages/Services/AugmentedReality";
import VirtualReality from "./components/pages/Services/VirtualReality";
import ClientTestimonials from "./components/pages/TestimonialsComponent/ClientTestimonials";
import ConstructionDesign from "./components/pages/Services/ConstructionDesign";
import ThreeDVirtual from "./components/pages/Services/ThreeDVirtual";
import AboutUs from "./components/pages/about/AboutUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { conf } from "./config";


function App() {
//   const  loadApi = () =>{
//     return fetch(`${conf.server}/api/visit`)
// } 
// useEffect(() => {
//     loadApi();
//   }, []); 

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="services" element={<Services />} />
          <Route path="ar-education" element={<ArEducation />} />
          <Route path="real-states" element={<RealState />} />
          <Route path="ecommerce" element={<Ecommerce />} />
          <Route path="vr-education" element={<VrEducation />} />
          <Route path="web-development" element={<WebDevelopment />} />
          <Route path="healthcare" element={<Healthcare />} />

          <Route
            path="software-development"
            element={<SoftwareDevelopment />}
          />
          <Route path="mobile-development" element={<MobileDevelopment />} />
          <Route path="architecture-design" element={<ArchitectureDesign />} />
          <Route
            path="3d-visualization-marketing"
            element={<VisualizationMarketing />}
          />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="our-team" element={<OurTeam />} />
          {/* <Route path="portfolio" element={<Portfolio />} /> */}
          <Route path="training" element={<Training />} />
          <Route path="augmented-reality" element={<AugmentedReality />} />
          <Route path="virtual-reality" element={<VirtualReality />} />
          <Route path="testimonials" element={<ClientTestimonials />} />
          <Route path="construction-design" element={<ConstructionDesign />} />
          <Route
            path="3d-visualization-marketing/3d-virtual"
            element={<ThreeDVirtual />}
          /> 
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
