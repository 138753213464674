import styled from "styled-components";

export const ServicesCard = styled.section`
   width: 100%;
   padding: 40px 0px;
   margin-top: 50px;
   overflow: hidden;
   position: relative;
   margin-top: 50px;
   .titel-head{
       flex-direction: column;
   }
   .bg-animation {
      position: absolute;
      top: -100px;
      left: 0;
      z-index: -1;
      .zoom-fade{
        animation-name: zoom-fade;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        -webkit-animation-name: zoom-fade;
        -webkit-animation-duration: 5s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
      }
      @keyframes zoom-fade{
        0% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
        50% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        100% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
      }
  }
`;

export const CardBox = styled.div`
    
`

export const SubTitle = styled.h5`
  color: #101729;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 10px;
`