import styled from 'styled-components';

export const BannerContainer = styled.div`
    width: 100%;
    height: 960px;
    background-image: url('/assets/images/home/bg.png');
    background-repeat: no-repeat;
    background-position: top 100% center;
    background-size: cover;
    padding-top: 130px;
    @media screen and (max-width: 576px){
        height: 460px;
        padding-top: 70px;
        background-position: top 0% left 0%;
    }

    /* .banner-content{
        @media screen and (max-width: 576px){
            margin-top: 50px;
            padding-left: 0px !important;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    } */

    .banner-img{
        padding-left: 20px;
        @media screen and (max-width: 576px){
            width: 100%;
            text-align: center;
            padding-left: 0px;
        }
        img{
            width: 85%;
        }
    }

    .banner-content{
        padding-left: 50px;
        @media screen and (max-width: 576px){
            margin-top: 50px;
            padding-left: 0px !important;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        h3{
            font-size: 3rem;
            color: #101729;
            font-family: 'Ubuntu';
            font-weight: 380;
            margin-bottom: 20px;
            @media screen and (max-width: 576px){
                font-size: 1.8rem;
            }
            span{
               color: #00C9FF;
               font-weight: 700;
               text-transform: capitalize;
            }
        }
        .circle-img{
            position: absolute;
            right: 12%;
            top: 55%;
            height: 300px;
            width: 300px;
            border-radius: 50%;
            background: none;
            border: 50px solid #2575fc;
            -webkit-animation: heartbeat 0.5s cubic-bezier(0.245, 0.325, 0.51, 1.305) infinite alternate;
            animation: heartbeat 0.5s cubic-bezier(0.245, 0.325, 0.51, 1.305) infinite alternate;
            opacity: 0.050;
            z-index:-1;
            @media screen and (max-width: 576px){
                top: 48%;
                border: 30px solid #2575fc;
                height: 150px;
                width: 150px;
            }
            img{
                width: 180px;
            
            }
            @keyframes heartbeat{
                00% {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                }
            }
            
        }
        
    }
`;


export const ButtonCard = styled.div`
        margin-top: 32px;
        gap: 20px;
        z-index: 99;
        @media screen and (max-width: 576px){
            margin-top: 5px;
        }
        .btn{
           transition: all 0.4s ease-in-out;
           position: relative;
           &:hover{
                transform: translateY(-3px);
           }
        }

       .contactBtn{
            padding: 10px 22px;
            background: linear-gradient(90.04deg, rgb(7, 202, 254) 1.2%, rgb(101, 220, 252) 99.97%);
            border: none;
            color: #fff;
            height: 46px;
            border-radius: 7px;
            font-weight: 500;
            font-size: 16px;
            @media screen and (max-width: 576px){
                height: 42px;
                font-size: 14px;
                padding: 6px 15px;
            }
       }

       .consultBtn{
            border: none;
            color: #fff;
            padding: 10px 22px;
            height: 46px;
            border-radius: 7px;
            font-weight: 500;
            font-size: 16px;
            background-color: #000000;
            @media screen and (max-width: 576px){
                height: 42px;
                font-size: 14px;
                padding: 6px 15px;
            }
       }
`;