import {MainContainer, Head, MainCardsbox, ReadMoreButon} from "./ItServices-style"
import ITServiceCards from "./ITServicesCards";
import HeadingTwo from "../Headings/HeadingTwo";
import { NavLink } from "react-router-dom";


export default function ITService(){
    return(
        <>
        <MainContainer>
            <div className="container">
                <Head>

                    <HeadingTwo title="We run all kinds of IT services that wow your success" />

                    <h5>
                        <span>6</span> 
                        YEARS’<br /> EXPERIENCE<br /> IN IT
                    </h5>
                    <div className="container">
                    
                    <p>We Provide VR education / trainings it is new era of personalized learning. VR technology has a long way to go and has a bright future in the education sector. </p>
                    <p>VR has already given reason to the industry to embrace it, as it is revolutionizing every sector. There are many VR solutions in the market that have elevated kids’ engagement to a new sphere.</p>
                    <p>Soon, teachers and entire educational systems will have options to move away from outdated teaching methods, thanks to Virtual Reality in the classroom.</p>
                    <p>The last few years, it has achieved great paces in education. Teachers and entire educational systems will have options to move away from outdated teaching methods.</p>
                    
                    <p>The new innovations, creativity and improved user interfaces are all about VARA EdTech. We aim to help you make more intelligent use of investments in the network, save your time and money.</p>
                    </div>
                   
                </Head>  

                <div className="row mt-5">
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <MainCardsbox>
                            <ITServiceCards 
                                image="assets/images/about/AR-education.png" 
                                title="Augmented Reality" 
                                text="Augmented reality is a technology that digitally puts a 'realworld' experience with a 3D picture. In the real world, this gives the user the appearance that the virtual." />
                                <ReadMoreButon type="button">
                                    <NavLink to="/augmented-reality" className="nav-link">Read More</NavLink>
                                </ReadMoreButon>         
                        </MainCardsbox>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <MainCardsbox>
                            <ITServiceCards 
                                image="assets/images/about/augmented-reality.png" 
                                title="AR Education" 
                                text="Get your message across! 3D characters can be appeared in a physical or digital room, and viewed through a VARA EdTech AR mobile app." />     
                                <ReadMoreButon type="button">
                                    <NavLink to="/ar-education" className="nav-link">Read More</NavLink>
                                </ReadMoreButon> 
                        </MainCardsbox>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <MainCardsbox>
                            <ITServiceCards 
                                image="assets/images/about/real-estate.png" 
                                title="Real Estate" 
                                text="Our technology is more suitable for the apartments that are under construction and could also  provide opportunity for the clients to choose floor/wall material, before it is finished." />
                                <ReadMoreButon type="button">
                                    <NavLink to="/real-states" className="nav-link">Read More</NavLink>
                                </ReadMoreButon> 
                        </MainCardsbox>
                    </div>
                </div>
            </div>

            
        </MainContainer>
        </>
    )
}