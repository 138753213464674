import styled from "styled-components";

export const MainContainer = styled.section`
   
`;

export const Head = styled.div`
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   text-align: center;

   h2{
       margin-bottom: 10px;
       max-width: 800px;
       margin: 0px auto;
       font-family: 'Nunito', sans-serif;
   }

   h5{
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 0.875rem;
       gap: 6px;
       span{
           font-size: 70px;
           font-weight: 700;
           line-height: 100px;
           color: #07CAFE;
       }
   }
   p{
       text-align: justify;
   }
`;

export const MainCardsbox = styled.div`
    width: 100%;
    padding: 25px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: 576px){
        padding: 20px 15px;
    }
    &:hover{
        margin-top: -5px;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 22%)
    }
`;

export const ReadMoreButon = styled.button`
     background: linear-gradient(90.04deg ,#07CAFE 1.2%,#65DCFC 99.97%);
    border-radius: 4px;
    color: #fff !important;
    padding: 8px 20px !important;
    border: none;
    margin-top: 10px;
`;