import { MainContainer } from "./Navbar-style";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ScrollToTop from "../../ScrollToTop";


export default function Navbar(){

  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 120);
    });
  }, []);


  const [isActive, setIsActive] = useState(false);




  const handleClick = event => {
       
    setIsActive(current => !current);

    event.currentTarget.classList.toggle('toggle-dropdown');

    // event.currentTarget.classList.add('activemy');

  };

  
    return(

      
      <>
      <MainContainer className={isActive ? 'bgwhite' : ''}>
         <ScrollToTop />
         <nav className={scroll ? "navbar navbar-expand-sm bg-light navbar-light fixed" : "navbar navbar-expand-sm bg-light navbar-light"} >
            <div className="container">
              <NavLink to="/" className="navbar-brand">
                <img className="white-logo" src="/assets/images/varaedtech-white-logo.png" alt="Logo" />
                <img className="dardk-logo" src="/assets/images/varaedtech-logo-dark.png" alt="Logo" />
              </NavLink>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink to="/" className={location.pathname === '/' ? "nav-link isactive": "nav-link"}>Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about-us" className="nav-link">About Us</NavLink>
                    </li>
                    <li className="nav-item dropdown" onClick={handleClick}>
                      <NavLink to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</NavLink>
                        <ul className="dropdown-menu">
                            
                            <li className="sub-dropdown" onClick={handleClick}>
                              <NavLink to="#" className="dropdown-item dropdown-toggle">Augmented Reality</NavLink>
                              <ul className="sub-dropdown-menu">
                                  <li><NavLink to="/ar-education" className="dropdown-item">AR Education</NavLink></li>
                                  <li>
                                      <NavLink to="/real-states" className="dropdown-item">Real States</NavLink>
                                  </li>                               
                              </ul>
                            </li>                              
                            <li className="sub-dropdown" onClick={handleClick}>
                                 <NavLink to="#" className="dropdown-item dropdown-toggle">Virtual Reality</NavLink>
                                <ul className="sub-dropdown-menu">                                  
                                    <li>
                                        <NavLink to="/ecommerce" className="dropdown-item">Ecommerce</NavLink>
                                    </li> 
                                    <li>
                                        <NavLink to="/vr-education" className="dropdown-item">VR Education</NavLink>
                                    </li> 
                                </ul> 
                            </li>
                            <li>
                              <NavLink to="/software-development" className="dropdown-item">Software Development</NavLink>
                            </li>
                            <li className="sub-dropdown" onClick={handleClick}>
                                 <NavLink to="#" className="dropdown-item dropdown-toggle">Application Development</NavLink>
                                <ul className="sub-dropdown-menu">                                  
                                    <li>
                                        <NavLink to="/web-development" className="dropdown-item">Web Development</NavLink>
                                    </li> 
                                    <li>
                                        <NavLink to="/mobile-development" className="dropdown-item">Mobile Development</NavLink>
                                    </li> 
                                </ul> 
                            </li>
                
                            <li className="dropdown sub-dropdown" onClick={handleClick}>
                               <NavLink to="#" className="dropdown-item dropdown-toggle">Healthcare</NavLink>  
                               <ul className="sub-dropdown-menu">                                  
                                    <li>
                                        <NavLink to="/healthcare" className="dropdown-item">Telemedicine</NavLink>
                                    </li> 
                                    {/* <li>
                                        <NavLink to="/3d-visualization-marketing" className="dropdown-item">3D visualization and <br/>Marketing</NavLink>
                                    </li>  */}
                                </ul>                             
                            </li>


                            <li className="dropdown sub-dropdown" onClick={handleClick}>
                               <NavLink to="#" className="dropdown-item dropdown-toggle">Construction Design</NavLink>  
                               <ul className="sub-dropdown-menu">                                  
                                    <li>
                                        <NavLink to="/architecture-design" className="dropdown-item">Architecture and Design</NavLink>
                                    </li> 
                                    <li>
                                        <NavLink to="/3d-visualization-marketing" className="dropdown-item">3D visualization and <br/>Marketing</NavLink>
                                    </li> 
                                </ul>                             
                            </li>


                        </ul>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink to="/portfolio" className="nav-link">Portfolio</NavLink>
                    </li> */}
                    <li className="nav-item">
                      <NavLink to="/our-team" className="nav-link">Our Team</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/testimonials" className="nav-link">Testimonials</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/training" className="nav-link">Training</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contact-us" className="nav-link conactbtn">Contact Us</NavLink>
                    </li>
                </ul>
              </div>
            </div>
          </nav>

        </MainContainer>

      </>
    )
    
}