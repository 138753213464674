import styled from "styled-components";

const H2 = styled.h2`
    font-size: 2.2rem;
    color: #1c1d3e;
    font-weight: 600;
    font-family: "Nunito",sans-serif;
    line-height: 40px;
    margin-bottom: 20px;
    text-transform: capitalize;
    @media screen and (max-width: 500px) {
        font-size: 1.5rem;
        line-height: 34px;
    }
`;

export default function HeadingTwo({ title }){
    
    return(
        <>
            <H2>{title}</H2>
        </>
    )
}