import styled from "styled-components";

export const ServicesCards = styled.section`
   width: 100%;
`;

export const AboutCard = styled.div`
   width: 100%;
   .ab-content-card{
       img{
           width: 150px;
       }
       h1{
         font-family: 'Nunito', sans-serif;
       }
   }
`;

export const MainCardsbox = styled.div`
    width: 100%;
    padding: 30px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: 576px){
        padding: 20px 15px;
    }
    &:hover{
        margin-top: -5px;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 22%)
    }
`;

export const ReadMoreButon = styled.button`
     background: linear-gradient(90.04deg ,#07CAFE 1.2%,#65DCFC 99.97%);
    border-radius: 4px;
    color: #fff !important;
    padding: 8px 20px !important;
    border: none;
    margin-top: 10px;
    text-decoration: all 0.3s ease-in-out;
    a{
      text-decoration: none;
      color: #fff;
    }
`;
