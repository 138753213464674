import styled from "styled-components";
import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";


const SuccessStories = styled.section`
    width: 100%;

    .story-img{
        height: 550px;
        object-fit: cover;
        border-radius: 6px;
        @media screen and (max-width: 576px){
            margin-bottom: 30px;
            height: auto;
        }
    }

    h2{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 41px;
        margin-bottom: 40px;
        @media screen and (max-width: 576px){
            font-size: 1.8rem;
            line-height: 38px;
            margin-bottom: 20px;
        }
        span{
            color: #00C9FF;
        }
    }

    .story-blue-txt{
        color: #00C9FF;
    }
`;

export default function RealState() {
    return (
        <>

            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/3d-real-estate.jpeg" mobbanner="assets/images/services/real-states.jpg" />




            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title="3D Real Estate" />

                    <p>For the real estate market we offer online interactive 3d walkthrough tours. 3D virtual tour is a general term for all kinds of technology that enables 360 tour. There are <strong>2 main advantage of our technology</strong> comparing to 360 Panoramic photorealistic render or 360 Photography based image (e.g. matterport) tour:</p>

                    <p>1. You can really walk and look around so you can get better experience of 3D space (360 PhotoRender of Matterport consist of many points in order to achieve illusion of walk or movement)</p>

                    <p>2. You can change colors or materials of the floor, wall, furniture etc.</p>

                    <p className="fw-bold">Our technology is more suitable for the apartments that are under construction and could also  provide opportunity (if needed) for the clients to choose floor/wall material, before it is finished.</p>

                    <p>Additionally, we can add links and pins with additional info about some elements in the house and you can use VR headset (e.g. google cardboards) if you want to experience 3D space in VR. Currently we are also working on dedicated web analytics tools for our product. Google analytics provide you info about time spent on some 3D scene/apartment, our analytics will provide additional info about how many time clients click on some type of floor/wall material – it might be interesting to find what is the preferable colors, materials, type of furniture etc.</p>

                    <p>Price can vary depending on the size of the apartment,  number of rooms, as well as if there is a special request regarding the furniture.</p>

                    <h2 className="svtitle">Walkthrough standard functionality implies following:</h2>

                    <p>1. Creating interface (adding buttons for each room with default room view, adding company logo)</p>

                    <p>2. Possibility to add  up to 5 material or info links in the walkthrough scene. ( for example, option for changing 3 floor material + 2 wall material according to clients request).</p>

                    <p>3. VR mode for mobile devices ( client need cellphone headset (e.g. google cardboard) and mobile phone in order to experience VR walkthrough)</p>

                </div>
            </div>


            <NumberSpeak />
            {/* 
           <SuccessStories>
               <div className="container">
                   <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                   </div>
                    
                    <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                              <img className="story-img" src="/assets/images/services/stories.png" alt="Success Story" />     
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                             <div className="stories-content">
                                <p>For the real estate market we offer online interactive 3d walkthrough tours. 3D virtual tour is a general term for all kinds of technology that enables 360 tour. There are 2 main advantage of our technology comparing to 360 Panoramic photorealistic render or 360 Photography based image (e.g. matterport) tour :</p>

                                <p className="story-blue-txt">1. You can really walk and look around so you can get better experience of 3D space (360 PhotoRender of Matterport consist of many points in order to achieve illusion of walk or movement)</p>

                                <p className="story-blue-txt">2. You can change colors or materials of the floor, wall, furniture etc.</p>   
                               
                                <p>Our technology is more suitable for the apartments that are under construction and could also provide opportunity (if needed) for the clients to choose floor/wall material, before it is finished.</p>
                               
                                <p>Additionally, we can add links and pins with additional info about some elements in the house and you can use VR headset (e.g. google cardboards) if you want to experience 3D space in VR. Currently we are also working on dedicated web analytics tools for our product. Google analytics provide you info about time spent on some 3D scene/apartment, our analytics will provide additional info about how many time clients click on some type of floor/wall material – it might be interesting to find what is the preferable colors, materials, type of furniture etc.</p>
                           
                            </div>   
                        </div>
                    </div>
               </div>
           </SuccessStories> */}

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}