import Navbar1 from "../../Header/Navbar/Navbar1";
import NumberSpeak from "./NumberSpeak";
import styled from "styled-components";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import ServicesBanner from "../ServicesBanner/ServicesBanner";



const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;

export default function ConstructionDesign() {
    return (
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/interior-exterior.jpeg" mobbanner="assets/images/services/interior-exterior.jpeg" />

            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title="Construction Design" />

                    <h2 className="svtitle">Interior & Exterior</h2>
                    <p>Whether the building is for civil servants, hospital patients, students, congregations, or volunteers, VARA EDTECH Company has the experience and expertise that clients can rely on to help them complete critical projects. As its own design and build company, The VARA EDTECH Company builds better partnerships by addressing ideas, questions and concerns from different fields under one roof.</p>
                    <p>Contact us today to learn more about how we can help you design your interior. You can also read the design build buying guide. This guide explains the benefits of the design-build method and provides step-by-step instructions for a successful project.</p>
                </div>
            </div>


            <NumberSpeak />

            <SuccessStories>
                <div className="container">
                    <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                    </div>

                    <div className="row mt-4">

                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>Mobile apps are revolutionizing the tech world today, and it is incredibly important to have an easy to use, user-friendly & highly dynamic mobile app. Not only do mobile applications ease our day-to-day operations, but they also help small and largescale companies access a wide variety of users worldwide. We are here to help you communicate with global clients and to provide you with the latest ideas and strategies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}