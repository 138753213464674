import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";

export default function SoftwareDevelopment(){
    return(
        <>
          <Navbar />

          <ServicesBanner deskbanner="assets/images/services/software-dev.png" mobbanner="assets/images/services/software-dev.png" />

          <div className="services-card-content">
                <div className="container">
                <br></br>
                    <HeadingH2 title="Software Develoment" />

                    <h2 className="svtitle">Custom Software Development</h2>  
                    
                    <p>We design enterprise software solutions that tackle vital business challenges and elevate them to new levels.</p>
                    
                    <h2 className="svtitle">IT security & Software</h2>

                    <p>We utilize the advanced technology of SiteConnect to connect sites that run on various types of servers and networks, reducing the misinterpretation of signals and data loss during transfer.</p>
                
                </div>
            </div>


            <NumberSpeak />

            <Newsletter />

            <Footer ishome={false}/>

        </>
    )
}