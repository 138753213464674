import styled from "styled-components";

export const MainContainer = styled.section`
  width: 100%;
  @media screen and (max-width: 576px) {
      padding-top: 0px;
  }
   .logo-cards{
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 40px 20px;
       background: #FFFFFF;
       box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
       border-radius: 10px;
       @media screen and (max-width: 576px) {
           margin-bottom: 30px;
       }

       img{
           max-width: 80%;
       }
   }
`;