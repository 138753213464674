import Navbar1 from "../../Header/Navbar/Navbar1";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import styled from "styled-components";
import NumberSpeak from "./NumberSpeak";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import HeadingH2 from "../../HeadingComponent/HeadingH2";



const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;

export default function VirtualReality() {
    return (
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/augmented-ban.jpg" mobbanner="assets/images/services/virtual-reality-mob.jpg" />

            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="Virtual Reality" />

                    <h2 className="svtitle">Virtual Reality</h2>
                    <p>VR is a computer-generated environment that uses scenes and objects that appear real to give users a sense of immersion in their surroundings. This environment is perceived through a device called a virtual reality headset or helmet. </p>
                    <p>With VR, you can immerse yourself in a video game as if you were one of the character's girlfriends, learn how to perform heart surgery, or improve the quality of your sports training to maximize performance. You can
                        While this may seem very futuristic, its origins aren't as recent as we might think, in fact many believe that one of his first virtual reality devices was called the Sensorama.</p>
                    <p>I think This is a machine with a built-in seat that plays 3D movies, emits smells, and produces vibrations to make the experience as vivid as possible. The invention dates back to the mid-1950s. Subsequent technology and software developments over the next few years led to progressive developments in both device and interface design.</p>

                </div>
            </div>


            <NumberSpeak />

            <SuccessStories>
                <div className="container">
                    <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>Augmented reality is a technology that digitally puts a 'realworld' experience with a 3D picture. In the real world, this gives the user the appearance that the virtual entity co-exists with them.</p>
                                <p>In gaming, AR is also used, giving players a more realistic experience and involving more senses. But in the shopping experience, it is helpful as well.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />


        </>
    )
}