import styled from "styled-components";

export const MainPageContainer = styled.section`
     width: 100%;
     padding: 40px 0px;
     @media screen and (max-width: 576px){
         margin-top: 110px;
     }
`;

export const Counter = styled.div`
    width: 100%;
    min-height: 240px;
    background: #FFFFFF;
    padding: 20px;
    box-shadow: 0px 0px 10.6122px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    @media screen and (max-width: 576px){
        margin-bottom: 30px;
    }

    img{
        width: 70px;
    }

    h3{
        color: #00C9FF;
        font-style: normal;
        font-weight: 700;
        font-size: 33.9592px;
        line-height: 51px;
        margin: 20px 0px 5px;
    }

    h4{
        color: #1E2A3A;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }
`;