import styled from "styled-components"

export const MainContaper = styled.section`
     width: 100%;
     padding: 0px;
     margin-top: -150px;
     @media screen and (max-width: 576px){
          margin-top: 120px;
     }
     .banner-img{
          @media screen and (max-width: 576px){
               width: 100%;
               text-align: center;
          }
     }
     .aboutImg{
          @media screen and (max-width: 576px){
               margin-top: 30px;
          }
     }
`;

export const SubTitle = styled.h5`
  color: #101729;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 10px;
`;

export const ContentCard = styled.div`
     position: relative;
     @media screen and (max-width: 576px){
          margin-top: 50px;
     }
     p{
          text-align: justify;
     }
`;

export const Button = styled.button`
     padding: 10px 22px;
     background: linear-gradient(90.04deg, rgb(7, 202, 254) 1.2%, rgb(101, 220, 252) 99.97%);
     border: none;
     color: #fff;
     height: 46px;
     border-radius: 7px;
     font-weight: 500;
     font-size: 16px;
     transition: all 0.4s ease-in-out;
     margin-top: 10px;
     @media screen and (max-width: 576px){
        height: 40px;
        padding: 8px 15px;
        font-size: 15px;
     }
     &:hover{
          transform: translateY(-3px); 
     }
     a{
          text-decoration: none;
          color: #fff;
     }
`;