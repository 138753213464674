import styled from "styled-components";

export const MainPortfolio = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 40px;
    .react-tabs{
        margin-bottom: 30px;
    }
    .react-tabs__tab-list{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        gap: 12px;
        flex-wrap: wrap;
        margin-bottom: 50px;
       
        li{
            border: none;
            padding: 11px 25px;
            background: #E1E8F0;
            border-radius: 150px;
            min-width: 130px;
            text-align: center;
            color: #101729;
            font-weight: 400;
            font-size: 1rem;
            transition: all 0.4s ease-in-out;
            @media screen and (max-width: 576px){
                padding: 8px 20px;
                font-size: 0.9rem;
            }
        }
        .react-tabs__tab.react-tabs__tab--selected{
            background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%);
            border-radius: 150px;
            color: #fff;
        }
    }
`;

