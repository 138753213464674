import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2"
import HeadingH3 from "../../HeadingComponent/HeadingH3";

import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";

export default function Healthcare() {
    return (
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/healthcare.jpg" mobbanner="assets/images/services/healthcare.jpg" />

            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="Healthcare" />

                    <h2 className="svtitle">Problems</h2>

                    <HeadingH3 title="Recent Pandemic has changed the way of healthcare system" />
                    <li>
                        You live in a rural area or don't have a clinic nearby
                    </li>
                    <li>If you need medical care on the go or away from home</li>
                    <li>Have limited movement, time, or transportation</li>
                    <li>Long waiting time</li>
                    <li>Increased risk of infection</li>
                    <p></p>
                    <h2 className="svtitle">Solutions</h2>

                    <p>Virtual / Augmented reality in healthcare will change the dynamics of healthcare as people realize how practical this technology can be, and also manages data using cloud system.</p>

                    <div className="row">
                        <HeadingH3 title="Telemedicine is the use of technology that enables remote healthcare" />
                        <hr></hr>
                        <div className="col-xl-6 col-lg-6 col-md-6">

                            <li>Easy Accessing the record of patient</li>
                            <li>Saves the space of placing files</li>
                            <li>Save Man Powers</li>
                            <li>Limited risk and exposure to infection</li>
                            <li>No or little waiting time</li>
                            <li>No need for transportation</li>
                            <li>Convenient</li></div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <img className="container" src="/assets/images/services/healthcare-benefits.jpg" alt="benefits" />
                        </div>
                    </div>
                    
                </div>
            </div>

            <NumberSpeak />

            <Newsletter />

            <Footer ishome={false} />
        </>
    )
}