import { CardBox } from "./ITServicesCards-style";

export default function ITServicesCard(props){
    return(
        <>
          <CardBox>
               <img src={props.image} alt="Services" />
               <h4>{props.title}</h4>
               <p>{props.text}</p>
          </CardBox>  
        </>
    )
}