import styled from "styled-components";
import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import HeadingThree from "../Headings/HeadingThree";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";
import { BiCheckShield, BiLock } from "react-icons/bi";
import { FiLayers, } from "react-icons/fi";
import { BsAward } from "react-icons/bs";


const SecurityServices = styled.section`
    .it-services-cards{
        padding: 20px;
        box-shadow: 0px 1px 20px 0px rgb(0 0 0 / 13%);
        margin-bottom: 30px;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
        &:hover{
            transform: translateY(-3px);
            box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 20%);
        }
        h4{
            font-size: 18px;
            color: #444;
            font-weight: 600;
            display: flex;
            align-items: center;
            column-gap: 8px;
            span{
                font-size: 50px;
                color: #2575fc;
            }
        }
    }
`;

const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;


export default function WebDevelopment(){
    return(
        <>
          <Navbar1 />

          <ServicesBanner deskbanner="assets/images/services/web-dev.jpg" mobbanner="assets/images/services/web-dev.jpg" />

            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="Web Develoment" />

                    <h2 className="svtitle">Web Develoment</h2>  
                    
                    <p>We boast a team of expert web app developers who create user-friendly and visually appealing web applications utilizing the latest technology to drive growth for your business.</p>
                    
                    <h2 className="svtitle">IT security & Software</h2>

                    <p>We employ the cutting-edge technology of SiteConnect to connect sites running on various types of servers and networks, minimizing the misinterpretation of signals and data loss during transfer.</p>
                
                </div>
            </div> 

            <SecurityServices>
                <div className="container">
                    <div className="text-center">
                        <HeadingThree title="Prominent IT Security Services" />
                    </div>
                   
                    <div className="row mt-5">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BiCheckShield /></span> Mitech Management Systems</h4>
                                <p>Our service offerings enhance customer experience throughout secure & highly functional end-to-end warranty management.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BiLock /></span> Reliable Multi-function Technology</h4>
                                <p>Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><FiLayers /></span> Highly Professional Staffs</h4>
                                <p>Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BsAward /></span> Infrastructure Integration Technology</h4>
                                <p>At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.</p>
                            </div>
                        </div>
                    </div>
                      
                    
                </div>
            </SecurityServices>

            <NumberSpeak /> 

            <SuccessStories>
               <div className="container">
                   <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                   </div>
                    
                    <div className="row mt-4">
                       
                        <div className="col-lg-12 text-center">
                             <div className="stories-content">
                                <p>Mitech specializes in technological and IT-related services such as product engineering, warranty management, building cloud, infrastructure, network, etc.</p>

                                <p>We’re available for 8 hours a day!</p>

                                <p>Contact to require a detailed analysis and assessment of your plan.</p>                            
                           
                            </div>   
                        </div>
                    </div>
               </div>
           </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}