import { OurTeamContainer, TeamRightCard } from "./OurTeams-style"
import { FaUsers } from "react-icons/fa";
import HeadingH3 from "../../HeadingComponent/HeadingH3";
import TeamCards from "./TeamCards";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function OurTeam() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <OurTeamContainer>
        <div className="container-fluid">
          <div className="mainteamrow row align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-12">
              <div className="teamcontent">
                <div className="title-effect title-effect-2">
                  <div className="ellipse"></div> <span><FaUsers /></span>
                </div>
                <HeadingH3 title="Meet Our Expert Team Member Will Ready For Your Service" />
                <p>We are seeking a reliable and dedicated individual to join our growing team at VARA EdTech. As a Team Member, you will be responsible for completing assigned tasks from the Team Leader or Manager, collaborating effectively with other team members, following company policies, and acting as a positive brand ambassador at all times.</p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12">
              <TeamRightCard>
                <Carousel responsive={responsive} autoPlay infinite={true} autoPlaySpeed={4000}>
                  <div><TeamCards image="assets/images/team/sunjay.jpg" designation="CEO & Founder" title="Sunjay Kumar" /></div>
                  <div><TeamCards image="assets/images/team/atul.jpg" designation="Technical Lead" title="Atul Goel" /></div>
                  <div><TeamCards image="assets/images/team/prakash.jpg" designation="Project Manager" title="Prakash Das" /></div>
                  <div><TeamCards image="assets/images/team/vivek.jpg" title="Vivek" designation="Marketing Manager" /></div>
                  <div><TeamCards image="assets/images/team/baris.jpg" designation="Full Stack" title="Barış Alagöz" /></div>
                  <div><TeamCards image="assets/images/team/bayram.jpg" title="BayRam" designation="Full Stack" /></div>
                  <div><TeamCards image="assets/images/team/peter.jpg" title="Peter" designation="Full Stack" /></div>
                  <div><TeamCards image="assets/images/team/shoriful.jpg" title="Shoriful" designation="Front End" /></div>
                  <div><TeamCards image="assets/images/team/ee.jpg" title="Emmanuel Eneyoh" designation="Backend" /></div>
                  <div><TeamCards image="assets/images/team/zain.jpg" title="Zain" designation="Android Developer" /></div>
                  {/* <div><TeamCards image="assets/images/team/emel.jpg" title="Emmeneul Eoreoluwa" designation="Full Stack" /></div> */}
                  <div><TeamCards image="assets/images/team/pradeep.jpg" title="Pradeep" designation="Full Stack" /></div>
                  <div><TeamCards image="assets/images/team/ammar.jpg" title="Ammar" designation="Full Stack" /></div>
                  <div><TeamCards image="assets/images/team/farahan.jpg" title="Farahan" designation="Front End" /></div>
                  <div><TeamCards image="assets/images/team/chibwe.jpg" title="Chibwe" designation="Front End" /></div>
                  <div><TeamCards image="assets/images/team/chebs-black.jpg" title="Chebs Black" designation="MERN Stack" /></div>
                </Carousel>
              </TeamRightCard>
            </div>
          </div>
        </div>
      </OurTeamContainer>
    </>
  )
}