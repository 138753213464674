import styled from "styled-components";

export const MainContainer = styled.section`
    width: 100%;
    height: auto;
    position: relative;
    background-image: url(/assets/images/about/client-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    padding-top: 0px;
    margin-top: 40px;
    padding-bottom: 5px;
    @media screen and (max-width: 576px){
        padding-bottom: 25px;
    }
    .topbg{
        margin-bottom: -40px;
    }

    .chead{
        h5{
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 15px;
            color: #101729;
            @media screen and (max-width: 576px){
                padding-top: 25px;
            }
        }
        h3{
            max-width: 800px;
            margin: 0px auto;
            font-family: 'Nunito', sans-serif;
        }
    }
   
`;

export const MainClientCard = styled.div`
    width: 100%;
    padding-bottom: 40px;
    position: relative;
    @media screen and (max-width: 576px) {
        padding-bottom: 275px;
    }
    .clientBoxes{
        position: relative;
        padding: 20px 0 20px 20px;
        @media screen and (max-width: 576px) {
            padding: 12px;
        }
        &:after{
       content: "";
            /* background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%); */
            background-color: #2575FC;
            box-shadow: 0px 0px 10.5747px rgba(0, 0, 0, 0.1);
            border-radius: 5.28736px;
            width: 20%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: -1;
            @media screen and (max-width: 576px) {
                width: 15%;
            }
    }
    }
    .react-multi-carousel-list{
        padding: 50px 20px;
        width: 100%;
        display: grid;
        position: static;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 576px) {
            padding: 30px 20px;
        }
        .react-multi-carousel-dot-list{
            top: 5%;
            position: static;
            li:first-child button{
                width: 200px;
                height: 200px;
                position: absolute;
                right: 24%;
                top: 0%;
                background-image: url(/assets/images/testimonial/client1.jpeg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 75%;
                background-color: transparent;
                border: none;
                @media screen and (max-width: 1400px){
                    width: 180px;
                    height: 180px;  
                    right: 25%;
                    top: 3%; 
                }
                @media screen and (max-width: 900px){
                    width: 140px;
                    height: 140px;
                    right: 15%;
                    top: 11%;
                }
                @media screen and (max-width: 576px){
                    width: 170px;
                    height: 170px;
                    background-size: 70%;
                    top: 54%;
                    left: 10%;
                }
                @media screen and (max-width: 380px){
                    width: 160px;
                    height: 160px;
                    top: 55%;
                    left: 7%;
                }
            }
            li:nth-child(2) button{
                width: 200px;
                height: 200px;
                position: absolute;
                right: 9%;
                top: -2%;
                background-image: url(/assets/images/team/sunjay.jpg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 75%;
                background-color: transparent;
                border: none;
                @media screen and (max-width: 1400px){
                    width: 180px;
                    height: 180px;  
                    right: 11%;
                    top: 3%; 
                }
                @media screen and (max-width: 900px){
                    width: 140px;
                    height: 140px;
                    top: 10%;
                    right: 1%;

                }
                @media screen and (max-width: 576px){
                    width: 170px;
                    height: 170px;
                    background-size: 70%;
                    top: 54%;
                    right: 4%;
                }
                @media screen and (max-width: 380px){
                    width: 160px;
                    height: 160px;
                    top: 55%;
                    right: 4%;
                
                }
            }

            li:last-child button{
                width: 200px;
                height: 200px;
                position: absolute;
                right: 16%;
                bottom: 10%;
                background-image: url(/assets/images/team/sunjay.jpg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 75%;
                background-color: transparent;
                border: none;
                @media screen and (max-width: 1400px){
                    width: 180px;
                    height: 180px;  
                    bottom: 15%;
                    right: 18%;
                }
                @media screen and (max-width: 900px){
                    width: 140px;
                    height: 140px;
                    bottom: 26%;
                    right: 7%;
                }
                @media screen and (max-width: 576px){
                    width: 170px;
                    height: 170px;
                    background-size: 70%;
                    bottom: 0%;
                    right: 24%;
                }
                @media screen and (max-width: 380px){
                    width: 160px;
                    height: 160px;
                    bottom: 1%;
                    right: 24%;
                }
            }  
        
        } 
    }
`;

export const Cardsboxes = styled.div`
   max-width: 620px;
   width: 100%;
   padding: 20px;
   background: #FFFFFF;
   box-shadow: 0px 0px 10.5747px rgba(0, 0, 0, 0.1);
   border-radius: 5.28736px;
   position: relative;
   @media screen and (max-width: 1540px){
        max-width: 600px;
   }
   @media screen and (max-width: 1400px){
        max-width: 550px;
   }
   @media screen and (max-width: 1050px){
        max-width: 480px;
   }
  
   p{
       font-size: 16px;
       color: #1c1d3e;
       font-weight: 600;
       line-height: 25px;
       @media screen and (max-width: 900px){
            font-size: 15px;
        }
       @media screen and (max-width: 576px) {
        font-size: 14px;
        line-height: 23px;
       }
       span{
          color: #2575FC;
          font-weight: 600;
          
       }
   }

   h4{
        margin-top: 38px;
        position: relative;
        max-width: 120px;
        color: #2575FC;
        font-size: 1.1rem;
        font-weight: 700;
        font-family: 'Ubuntu';
       &:before{
        content: "";
        background: #2575FC;
        border-radius: 10px;
        width: 70px;
        height: 4px;
        position: absolute;
        left: 0%;
        top: -15px;
        z-index: 1;
       }
       &:after{
        content: "";
        background: #2575FC;
        border-radius: 10px;
        width: 15px;
        height: 4px;
        position: absolute;
        right: 22%;
        top: -15px;
        z-index: 1;
    }
       
  }

  h6{
    font-size: 14px;
    font-weight: 500;
    color: #1c1d3e;
    }
    .quote{
        width: 90px;
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 1;
    }
`;