import styled from "styled-components";

export const NumberContainer = styled.section`
    width: 100%;
    background-color: #F3F5FF;
    margin-bottom: 80px;
`;

export const NumberContent = styled.div`
    h2{
        font-size: 30px;
    }
`;

export const NumberSpeakCard = styled.div`
    position: relative;
    bottom: -80px;
    @media screen and (max-width: 576px){
        bottom: -20px;
    }
`;

export const Counter = styled.div`
    width: 100%;
    min-height: 230px;
    background: #FFFFFF;
    padding: 20px;
    box-shadow: 0px 0px 10.6122px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 576px){
        margin-bottom: 30px;
    }

    img{
        height: 70px;
    }

    h3{
        color: #00C9FF;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 51px;
        margin: 15px 0px 5px;
    }

    h4{
        color: #1E2A3A;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }
`;