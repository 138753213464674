import styled from "styled-components";

export const BannerCards = styled.div`
  width: 100%;
  /* height: 500px;
  padding-top: 170px;
  background-image: url(/assets/images/bg/header-bg-1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center; */

  display: block;
  padding-top: 150px;
  z-index: 5;
  background-color: rgb(251, 243, 237);
  @media screen and (max-width: 576px){
    padding-top: 130px;
  }
  h3{
      font-size: 42px;
      color: #1c1d3e;
      line-height: 60px;
      font-weight: 700;
      margin-bottom: 15px;
      font-family: 'Nunito', sans-serif;
      &::first-letter{
        color: #2575FC;
      }
      @media screen and (max-width: 576px){
        font-size: 32px;
        margin-bottom: 10px;
        line-height: 36px;
      }
      span{
          color: #2575FC;
      }
      
  }

    p{
      padding-bottom: 20px;
      @media screen and (max-width: 576px){
        padding-bottom: 10px;
      }
    }


  .page-title-pattern {
    width: 100%;
      z-index: 1;
      .img-fluid {
        max-width: 100%;
        height: auto;
    }
  }


  .breadcrumb{
      @media screen and (max-width: 576px){
          padding-bottom: 10px;
      }
      .breadcrumb-item{
        font-weight: 500;
        &:last-child{
          color: #2575fc;
          font-weight: 500;
        }
        a{
          color: #5f5f5f;
          text-decoration: none;
          transition: all 0.4s ease-in-out;
           &:hover{
             color: #2575fc;
           }
        }
      }
  }

`;