import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import styled from "styled-components";
import NumberSpeak from "./NumberSpeak";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";

const SuccessStories = styled.section`
  width: 100%;

  .story-img {
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
  }

  h2 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 38px;
      margin-bottom: 20px;
    }
    span {
      color: #00C9FF;
    }
  }

  .story-blue-txt {
    color: #00C9FF;
  }
`;

const WalkthroughTitle = styled.h2`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #0CCBFE;
  margin-bottom: 20px;
`;


const WalkthroughList = styled.ul`
  list-style: disc;
  margin-left: 20px;
`;

const WalkthroughListItem = styled.li`
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`
const CustomizationList = styled.ul`
  list-style: disc;
  margin-left: 20px;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const CustomizationListItem = styled.li`
  margin-bottom: 16px;
`;
const VisualizationMarketing = () => {
    const address = `https://varahousing.vercel.app`;

    return (
        <>
            <HelmetProvider>
                <title>Vara Edtech | 3D House Configurator and Virtual Walkthrough</title>
                <meta name="description" content="Create your dream home with Vara Edtech's 3D House Configurator. Customize every detail and visualize your dream home in stunning realism. Explore our vast selection of textures, materials, and furniture options. Collaborate with experts and elevate your home buying experience. Take the first step towards your dream home today!" />
                <meta name="keywords" content="3D house configurator, virtual walkthrough, home customization, interior design, architecture, real estate, Vara Edtech" />
            </HelmetProvider>

            <Navbar1 />

            <div className="services-card-content">
                <div className="container">
                    <div className="three-d">
                        <p>
                            <a href={address} target="_blank" rel="noopener noreferrer">
                                3D Configurator
                            </a>
                        </p>
                    </div>

                    <div className="threed-visual">
                        <iframe src={address} width="100%" height="100%" title="3D Virtual"></iframe>
                    </div>

                    <br />

                    <WalkthroughTitle>3D House Configurator:</WalkthroughTitle>

                    <CustomizationList>
                        <CustomizationListItem>
                            <strong>Exquisite Customization:</strong> Immerse yourself in a world of endless possibilities as you customize every detail of your dream home.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Unleash Your Creativity:</strong> Let your imagination run wild as you design a home that reflects your unique style and personality.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Seamless User Experience:</strong> Our intuitive interface ensures a smooth and enjoyable journey through the customization process, making it a breeze to bring your vision to life.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Unparalleled Realism:</strong> Experience the power of our advanced rendering technology that delivers stunningly realistic visuals, allowing you to visualize your dream home in breathtaking detail.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Limitless Selection:</strong> Explore our vast collection of textures, materials, and furniture options, curated to cater to diverse tastes and preferences.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Collaborate with Experts:</strong> Architects and interior designers can leverage our configurator to collaborate with clients, presenting visually stunning 3D designs that captivate and impress.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Privacy and Security:</strong> We prioritize the security of your design projects, ensuring that your personal information and creative ideas are safeguarded.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Elevate Your Home Buying Experience:</strong> Stand out from the crowd by offering your customers the opportunity to personalize their future homes using our cutting-edge 3D house configurator.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Transform Your Vision into Reality:</strong> Witness the magic unfold as you see your dream home come to life, with every element precisely tailored to your desires.
                        </CustomizationListItem>
                        <CustomizationListItem>
                            <strong>Start Designing Today:</strong> Take the first step towards creating the home of your dreams by diving into our immersive and empowering 3D house configurator.
                        </CustomizationListItem>
                    </CustomizationList>

                    <WalkthroughTitle>3D Walkthrough Tours for Real Estate:</WalkthroughTitle>

                    <p>
                        For the real estate market, we offer online interactive 3D walkthrough tours. Our technology provides a realistic experience where you can truly walk and look around, allowing you to better understand the 3D space compared to traditional 360 PhotoRender tours.
                    </p>
                    <p>
                        With our virtual walkthroughs, you can also customize the colors and materials of the floor, wall, furniture, and more, giving you the opportunity to personalize the space according to your preferences.
                    </p>
                    <p>
                        Our technology is particularly beneficial for apartments under construction, as it allows clients to choose floor/wall materials before they are finished. Additionally, we can add links and pins with additional information about specific elements in the house, providing a comprehensive virtual experience.
                    </p>
                    <p>Pricing varies based on apartment size, number of rooms, and specific furniture requests.</p>

                    <WalkthroughTitle>Walkthrough Standard Functionality:</WalkthroughTitle>
                    <p>Our walkthrough standard functionality includes:</p>
                    <WalkthroughList>
                        <WalkthroughListItem>
                            <strong>Customizable 3D House Model:</strong> Experience the flexibility of our 3D house configurator, allowing you to easily customize every aspect of your house. From the exterior textures to the interior furnishings, you have complete control over the design process.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Extensive Material Selection:</strong> Choose from a diverse collection of textures and materials to create the perfect look and feel for your home. Whether you prefer a modern minimalist style or a cozy traditional ambiance, our configurator offers a wide range of options to suit your taste.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Real-Time Visualization:</strong> See your changes come to life instantly! Our advanced rendering technology provides a realistic preview of your customized house, ensuring that every detail is exactly as you envisioned it.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Replaceable Furniture:</strong> Tailor your living space by easily swapping out furniture pieces. Choose from our selection of 3D furniture models, including kitchen, sofa, table, lamp, and more, to find the perfect fit for your home.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Seamless User Experience:</strong> Our configurator features an intuitive and user-friendly interface, making the customization process a breeze. Effortlessly navigate through the options and bring your vision to life with ease.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Commercial Projects Welcome:</strong> Architects and interior designers can leverage our configurator to showcase their vision to clients. Impress them with stunning 3D visuals and unlock new business opportunities.
                        </WalkthroughListItem>
                        <WalkthroughListItem>
                            <strong>Privacy and Security:</strong> Rest assured that your design projects are in safe hands. We prioritize your privacy and protect your data, ensuring that your personal information and creative ideas remain confidential.
                        </WalkthroughListItem>
                    </WalkthroughList>

                </div>
            </div>





            <NumberSpeak />

            <SuccessStories>
                <div className="container">
                    <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>Mobile apps are revolutionizing the tech world today, and having an easy-to-use, user-friendly, and highly dynamic mobile app is crucial. Mobile applications not only streamline day-to-day operations but also provide access to a wide variety of users worldwide. We are here to help you communicate with global clients and provide you with the latest ideas and strategies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />
        </>
    );
};

export default VisualizationMarketing;