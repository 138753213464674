import { MainContainer, MainClientCard, Cardsboxes } from "./CLientsFeedback-style";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HeadingH3 from "../../HeadingComponent/HeadingH3";

export default function ClientsFeedback(){
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <>
            {/* <MainContainer>
                <div className="topbg">
                    <img src="/assets/images/about/bg2.png" className="w-100" />
                </div>
                <div className="container">
                    <div className="chead text-center pt-4">
                        <h5>CREATIVE TEAM</h5>
                        <HeadingH3 title="You Can See Our Clients Feedback What You Say?" />
                    </div>

                    <MainClientCard className="clientsfeedback">
                        <div className="row mt-5">
                            <div className="col-lx-6 col-lg-7 col-md-8 col-sm-12">
                                <Carousel responsive={responsive} showDots={true} arrows={false} autoPlay autoPlaySpeed={3000} >
                                    <div className="clientBoxes">
                                        <Cardsboxes>
                                            <p><span>VARA EdTech</span> is looking for a dependable, committed Team Member to join our developing organization. The Team Member's obligations incorporate taking.</p>
                                            <h4>John Doeh</h4>
                                            <h6>CEO of company</h6>
                                            <img className="quote" src="/assets/images/about/quote.png" alt="Client" />
                                        </Cardsboxes> 
                                    </div>
                                    <div className="clientBoxes">
                                        <Cardsboxes>
                                            <p><span>VARA EdTech</span> is looking for a dependable, committed Team Member to join our developing organization. The Team Member's obligations incorporate taking.</p>
                                            <h4>Alina</h4>
                                            <h6>CEO of company</h6>
                                            <img className="quote" src="/assets/images/about/quote.png" alt="Client" />
                                        </Cardsboxes> 
                                    </div>
                                    <div className="clientBoxes">
                                        <Cardsboxes>
                                            <p><span>VARA EdTech</span> is looking for a dependable, committed Team Member to join our developing organization. The Team Member's obligations incorporate taking.</p>
                                            <h4>James</h4>
                                            <h6>Business Analyst</h6>
                                            <img className="quote" src="/assets/images/about/quote.png" alt="Client" />
                                        </Cardsboxes> 
                                    </div>
                                    
                                </Carousel>
                            </div>
                           
                        </div>
                    </MainClientCard>
                </div>
            </MainContainer>   */}
        </>
    )
}