import Footer1 from "../Footer/Footer1";
import Navbar from "../Header/Navbar/Navbar";
import About from "./About/About";
import Banner from "./Banner/Banner";
import HappyClients from "./HappyClientsComponent/HappyClients";
import OurPortfolio from "./OurPortfolio/OurPortfolio";
import OurServices from "./OurServicesComponent/OurServices";
import OurTeam from "./OurTeamComponent/OurTeam";
import OurVisionMision from "./OurVisionMisionComponent/OurVisionMision";
import Testimonials from "./Testimonials/Testimonials";

export default function Home(){

    return(
        <>
          
           <Navbar />

           <Banner />

           <About />

           <OurVisionMision />

           <OurServices />

           <OurTeam />

           <Testimonials />

           <HappyClients />

           {/* <OurPortfolio /> */}

           <img className='footer-mobile-bg' src="/assets/images/bg/footer-bg.png" alt="Footer bg"/>

           <Footer1 ishome={true} />
 
        </>
    )
}