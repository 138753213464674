import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH1 from "../../HeadingComponent/HeadingH1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";
import ShoesXR from "../WebXR/ShoesXR";

export default function Ecommerce() {
  return (
    <>
      <Navbar1 />

      <ServicesBanner deskbanner="assets/images/services/ecommerce.png" mobbanner="assets/images/services/ecommerce.png" />

      <div className="services-card-content">
        <div className="container">
          <br />
          <br />
          <HeadingH2 title="Experience Product Customization like Never Before!" className="highlight-heading" />
          <ShoesXR />
          <br />
        </div>
      </div>

      <div className="services-card-content">
        <div className="container">
          <HeadingH1 title="E-commerce Solutions Tailored to Your Business Needs" />

          <p>
            Welcome to our e-commerce services page. We specialize in crafting digital solutions that empower businesses like yours to sell products and services online seamlessly. Whether you're a startup or an established brand, our team of experts are dedicated to leveraging their years of experience to elevate your online presence.
          </p>

          <h2 className="svtitle">E-commerce Website Development</h2>
          <p>
            We build personalized, user-friendly websites that not only showcase your unique products and services but also seamlessly integrate with your existing systems. We ensure your online store represents your brand effectively.
          </p>

          <h2 className="svtitle">Online Payment Integration</h2>
          <p>
            Simplify transactions with our secure online payment solutions. We integrate popular payment platforms like credit cards, PayPal, ensuring your customers enjoy a seamless and secure checkout experience.
          </p>

          <h2 className="svtitle">Inventory Management</h2>
          <p>
            With our robust inventory management solutions, track your sales and manage your inventory effectively, letting you focus on what matters most - growing your business.
          </p>

          <h3 className="svtitle">Customer Relationship Management (CRM)</h3>
          <p>
            Nurture your customer relationships with our CRM solutions, ensuring your customers enjoy personalized experiences that drive loyalty and enhance customer retention.
          </p>
          <h3 className="svtitle">Marketing and Advertising</h3>
          <p>
            We'll help you reach your target audience and promote your products and services through targeted marketing and advertising campaigns.
          </p>

          <h3 className="svtitle">Technical Support and Maintenance</h3>
          <p>
            Our team will provide ongoing technical support and maintenance to keep your website running smoothly and to address any issues that arise.
          </p>

          <HeadingH1 title="Benefits of E-commerce Services" />

          <h3 className="svtitle">Increased Sales</h3>
          <p>
            By offering your products and services online, you'll reach a wider audience and increase your sales.
          </p>

          <h3 className="svtitle">Improved Customer Experience</h3>
          <p>
            Our e-commerce solutions are designed to provide an excellent customer experience so you can build loyalty and trust with your customers.
          </p>

          <h3 className="svtitle">Streamlined Operations</h3>
          <p>
            Our inventory management and CRM solutions will help you streamline your operations and increase efficiency, so you can focus on growing your business.
          </p>

          <h3 className="svtitle">Increased Visibility</h3>
          <p>
            Our marketing and advertising services will help you reach your target audience and increase your visibility, so you can attract more customers.
          </p>
        </div>

        <div className="container">
          <h3 className="svtitle">Conclusion</h3>
          <p>
            Our e-commerce services are designed to help you grow your business and reach your goals. Whether you're a small startup or a large enterprise, our team is here to help you succeed. Contact us today to learn more about how we can help you take your business online.
          </p>
        </div>
      </div>

      <NumberSpeak />

      <Newsletter />

      <Footer ishome={false} />
    </>
  );
}
