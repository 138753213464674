import HeadingH3 from "../../HeadingComponent/HeadingH3";
import { ServicesCard, SubTitle } from "./OurServices-style"
import { FaCubes } from "react-icons/fa";
import Cards from "./Cards";

export default function OurServices(){
    return(
      <>
         <ServicesCard>
                <div className="bg-animation">
                    <img className="zoom-fade" src="/assets/images/home/pattren2.png" alt="" />
                </div>
                <div className="container">
                    <div className="titel-head d-flex justify-content-center align-items-center text-center">
                        <div className="title-effect title-effect-2">
                            <div className="ellipse"></div> <span><FaCubes /></span>
                        </div>
                        <SubTitle>Our Service</SubTitle>
                        <HeadingH3 title="We’ve been serving our best!" />
                    </div>
                    <div className="row mt-4">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/education.png" 
                                title="VR / AR Education" 
                                description="Virtual classrooms provide a convenient solution for both teachers and students globally." />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/realstate.png" 
                                title="VR / AR Real Estate" 
                                description="Buyers can save time and money by virtually visiting properties, as well as decorating and customizing furniture, materials, and colors." />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/telemedicine.png" 
                                title="Tele Medicine" 
                                description="VR/AR in healthcare will change the dynamics of healthcare as people realize how practical this technology can be, and also manages data using cloud system." />
                        </div> 
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/ecommerce.png" 
                                title="AR Ecommerce" 
                                description="Experience the product before buying, using AR to display the appearance right in front of you through your phone's camera." />
                        </div>    
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/cloudService.png" 
                                title="Cloud Services" 
                                description="Our team of experts offers innovative cloud computing consulting services to enhance your business productivity." />
                        </div>  
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <Cards 
                                icon="/assets/images/home/distribute.png" 
                                title="Distribute Apps" 
                                description="Our team of experts provides app distribution and development services for platforms such as Android and iOS." />
                        </div>                  
                    </div>
                </div>
         </ServicesCard>
      </>
    )
}   