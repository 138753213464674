import styled from "styled-components";
import Footer from "../../Footer/Footer";
import Navbar1 from "../../Header/Navbar/Navbar1";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import Newsletter from "../../NewsletterComponent/Newletter";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import NumberSpeak from "./NumberSpeak";

const SuccessStories = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #00C9FF;
    }
}

.story-blue-txt{
    color: #00C9FF;
}
`;

export default function ArchitectureDesign() {
    return (
        <>
            <Navbar1 />

            <ServicesBanner deskbanner="assets/images/services/3d-parametric.png" mobbanner="assets/images/services/3d-parametric.png" />


            <div className="services-card-content">
                <div className="container">

                    <HeadingH2 title="Architecture Design" />
                    <h2 className="svtitle">3D parametric configurators</h2>
                    
                    <p>VARA EdTech provides solutions for architects, designers, entrepreneurs, investors and real estate companies to accelerate and customize the design process including unique design solutions.</p>
                    <p>
                    Enables design from parametric 3D product configurator to computer-aided design with complex non-standard geometries. Automate and streamline the design-to-manufacture process for complex designs and non-standard geometries using computer design, digital fabrication and interactive visualization tools.
                    </p>
                </div>
            </div>


            <NumberSpeak />

            <SuccessStories>
                <div className="container">
                    <div className="text-center">
                        <h2>Learn More About Our <span>Success Stories</span></h2>
                    </div>

                    <div className="row mt-4">

                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>Mobile apps are revolutionizing the tech world today, and it is incredibly important to have an easy to use, user-friendly & highly dynamic mobile app. Not only do mobile applications ease our day-to-day operations, but they also help small and largescale companies access a wide variety of users worldwide. We are here to help you communicate with global clients and to provide you with the latest ideas and strategies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SuccessStories>

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}