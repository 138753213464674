import { MainContainer, TestimonialContent } from "./Testimonials-style";
import HeadingH3 from "../../HeadingComponent/HeadingH3";
import { BiComment } from "react-icons/bi";
import ClientCards from "./ClientCards";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export default function Testimonials(){
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <>
           <MainContainer className="Testimonials">
           
                <div className="container">
                    <div className="titel-head d-flex justify-content-center align-items-center">
                        <div className="title-effect title-effect-2">
                            <div className="ellipse"></div> <span><BiComment /></span>
                        </div>
                        <HeadingH3 title="You Can See our Clients Feedback what you say?" />
                    </div>  
                    <TestimonialContent>
                      <div className="row justify-content-center">
                          <div className="col-xl-7 col-lg-8 col-md-8">
                              <Carousel responsive={responsive} autoPlay infinite={true} autoPlaySpeed={3000} showDots={true} arrows={false}>
                                <div><ClientCards image="/assets/images/testimonial/client1.jpeg" name="Mr. Amjad"/></div>
                                {/* <div><ClientCards image="/assets/images/testimonial/clients2.png" name="Lana Roadse" /></div>
                                <div><ClientCards image="/assets/images/testimonial/clients3.png" name="Lana Roadse" /></div>
                                <div><ClientCards image="/assets/images/testimonial/clients4.png" name="Lana Roadse" /></div> */}
                            </Carousel>
                          </div>
                      </div>
                       
                    </TestimonialContent>
                </div>
            </MainContainer>
        </>
    )
}