import Navbar1 from "../../Header/Navbar/Navbar1";
import ITServicesCard from "../about/ITServicesCards";
import Banner from "../BannerComponent/Banner";
import NumberSpeak from "./NumberSpeak";
import { ServicesCards, AboutCard, MainCardsbox, ReadMoreButon } from "./Services-style";
import HeadingThree from "../Headings/HeadingThree";
import Testimonials from "../../Home/Testimonials/Testimonials";
import OurTeam from "../../Home/OurTeamComponent/OurTeam";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import { NavLink } from "react-router-dom";

export default function Services(){
    return(
        <>
           <Navbar1 />

           <Banner title="Services" subtitle="We’ve been serving our best!" homeurl="/" activelink="Services" />

           <ServicesCards>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/augmented.png" title="Augmented Reality" text="Augmented Reality (AR) is an augmented version of the real physical world, achieved using digital visuals, sounds, or other sensory stimuli and provided by technology" />
                                <ReadMoreButon type="button">
                                    <NavLink to="/augmented-reality">Read More</NavLink>
                                </ReadMoreButon> 
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/virtual.png" title="Virtual Reality" text="VR is a computer-generated environment that uses scenes and objects that appear real to give users a sense of immersion in their surroundings" />
                                <ReadMoreButon type="button">
                                    <NavLink to="/virtual-reality">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/software.png" title="Software Development" text="We design enterprise software solutions that tackle vital business challenges and elevate them to new levels." />
                                <ReadMoreButon type="button">
                                    <NavLink to="/software-development">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/web.png" title="Web Development" text="We boast a team of expert web app developers who create user-friendly and visually appealing web applications utilizing the latest technology to drive growth for your business." />
                                <ReadMoreButon type="button">
                                    <NavLink to="/web-development">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/mobile.png" title="Mobile Development" text="We are Thailand's leading mobile app development company, building robust apps for brands that require mobile-first solutions" />
                                <ReadMoreButon type="button">
                                    <NavLink to="/mobile-development">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/healthcare.png" title="Healthcare" text="VR / AR healthcare will change the dynamics of healthcare as people realize how practical this technology can be, and also manages data using cloud system." />
                                <ReadMoreButon type="button">
                                    <NavLink to="/healthcare">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/icons/construction-design.png" title="Construction Design" text="Whether the building is for civil servants, hospital patients, students, congregations, or volunteers, VARA EDTECH Company has the experience and expertise that clients can rely on to help them complete critical projects" />
                                <ReadMoreButon type="button">
                                    <NavLink to="/construction-design">Read More</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                    </div>
                </div>
           </ServicesCards>

           <NumberSpeak />


            <AboutCard>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img">
                                <img src="assets/images/home/home-about.png"  alt=""/>
                            </div>    
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="ab-content-card">
                                <img src="/assets/images/varaedtech-logo-dark.png" alt="Logo" />
                                <HeadingThree title="VARA EdTech Connects you with Interactive world and innovation"  />
                                <p>You need a creative software development company, like VARA EdTech, to really get serious about the immersive digital realm.</p>
                                <p>In terms of augmented and virtual reality software and AI applications, this is particularly true that VARA EdTech Team provides best service.</p>
                                <p>Looking to create an app that digitally enables your consumers to try on clothes in their homes or even preview trendy furniture? Do you have some understanding of virtual or mixed reality software to build and implement? In the desire to achieve your next machine learning app by the talented team of coders and designers? VARA EdTech has covered all of these for you!.
                                We are a cutting-edge, creative production house based in Thailand with a strong commitment to quality, creativity and performance. Our team consists of skilled technicians who can provide you with robust tools for the virtual world, applications for artificial intelligence, outsourcing of VR, AI and AR, and other services.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </AboutCard>

            <Testimonials />

            <OurTeam />

            <Newsletter />

            <Footer ishome={false} />

        </>
    )
}