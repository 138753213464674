import { FooterContainer, FooterBox, List } from './Footer-style';
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
import { NavLink } from "react-router-dom";


export default function Footer({ishome}){
    return(
        <>
          
          <FooterContainer ishome={ishome}>
               
                <div className='container'>
                    <div className='row pt-5'>
                        <div className='col-xl-3 col-lg-3 col-md-3'>
                            <FooterBox>
                                <img className="footer-logo mb-3" src="/assets/images/varaedtech-footer-logo.png" alt="Logo" />
                                <p>We are a cutting-edge, creative production house based in Thailand with a strong commitment to quality, creativity and performance.</p>
                            </FooterBox>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6'>
                            <FooterBox>
                                <ul className='footer-list'>   
                                    <List>
                                        <NavLink to="/augmented-reality" className="footer-link">Augmented Reality</NavLink>
                                    </List>                               
                                    <List>
                                        <NavLink to="/virtual-reality" className="footer-link">Virtual Reality</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/software-development" className="footer-link">Software development</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/web-development" className="footer-link">Web development</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/mobile-development" className="footer-link">Mobile development</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/healthcare" className="footer-link">Healthcare</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/3d-visualization-marketing" className="footer-link">3D Virutal Tour</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/construction-design" className="footer-link">Construction Design</NavLink>
                                    </List>
                                </ul>
                            </FooterBox>                      
                        </div>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6'>
                            <FooterBox>
                                <ul className='footer-list'>
                                    <List>
                                        <NavLink to="/about-us" className="footer-link">About</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/services" className="footer-link">Service</NavLink>
                                    </List>
                                    {/* <List>
                                        <NavLink to="/portfolio" className="footer-link">Portfolio</NavLink>
                                    </List> */}
                                    <List>
                                        <NavLink to="/testimonials" className="footer-link">Testimonials</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/training" className="footer-link">Trainings</NavLink>
                                    </List>
                                </ul>
                            </FooterBox>   
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4'>
                            <FooterBox>
                                <ul className='footer-list address-list'>
                                    <List>
                                        Address :- <span className='address'>5th Floor, Forum Tower, 184 Ratchadaphisek Rd, Khwaeng Huai Khwang, Huai Khwang, Bangkok 10310, Thailand</span>
                                    </List>
                                    <List>
                                        Email ID :- <a href="mailto:info@varaedtech.com">info@varaedtech.com</a>
                                    </List>
                                    <List>                              
                                        Phone No:- <a href="tel:+6680 053 8839">+66 94 887 7955</a>
                                    </List>
                                </ul>
                            </FooterBox> 
                        </div>
                    </div> 
                </div>
                <div className='copyright'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-6 col-lg-6 col-md-6'>
                                <p>Unlock Your Business's Potential with VARA EdTech - 2023 © All Rights Reserved.</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6'>
                                <div className='footer-social'>
                                    <ul className='d-flex'>
                                        <li>                                  
                                            <a href="https://www.facebook.com/varaedtech.official" target="_blank" rel="noopener noreferrer"><BsFacebook style={{fontSize: "17px"}}/> Facebook</a>
                                        </li> 
                                        <li>   
                                            <a href="https://twitter.com/EdtechVara" target="_blank" rel="noopener noreferrer"><AiFillTwitterCircle style={{fontSize: "18px"}} /> Twitter</a>
                                        </li>
                                        <li> 
                                            <a href="https://www.instagram.com/varaedtech/" target="_blank" rel="noopener noreferrer"><BsInstagram style={{fontSize: "17px"}} /> Instagram</a>
                                        </li> 
                                        <li>
                                            <a href="https://www.linkedin.com/company/vara-edtech/" target="_blank" rel="noopener noreferrer"><AiFillLinkedin style={{fontSize: "18px"}} /> Linkedin</a>
                                        </li>                                            
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </FooterContainer>

        </>
    )
}

